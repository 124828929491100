import { componentToggle, closeAllPanels, setDefaultBoardSettings, showAlert } from './uiUtils';
import { uploadPdf, getFilesOfBoard, fileSelectionBoard } from '../apiRequests';
import { BoardUser, UserFile } from '../../../../src/commonTypes';
import { isBoardOwner, isRoleFacilitator } from './toolsUI';

const uploadButton = document.querySelector<HTMLElement>('#board-menu-upload');
if (uploadButton) {
	uploadButton.onclick = handleUploadButtonClick;
}

function handleUploadButtonClick(e: Event) {
	e.preventDefault();
	const boardId = document.body.dataset.boardId!;
	closeAllPanels();
	uploadButton?.classList.add('buttonActive');
	componentToggle(document.getElementById('upload-container')!);

	// Call the API to fetch the updated list of files
	getFilesOfBoard(boardId).then((response) => {
		renderFileList(response);
	});

	const submitButton = document.querySelector<HTMLButtonElement>('#upload-button');
	if (submitButton) {
		submitButton.onclick = uploadFile;
	}

	const fileList = document.getElementById('file-list');
	if (fileList) {
		fileList.onclick = fileSelection;
	}
}

const fileSelection = function (e: Event) {
	e.preventDefault();

	console.log('[isBoardOwner()]', isBoardOwner());
	console.log('[isRoleFacilitator()]', isRoleFacilitator());

	if (!isBoardOwner() && !isRoleFacilitator()) {
		showAlert('Only the board owner/facilitator can change the PDF file.');
		return;
	}

	// Assert that event.target is an HTMLElement
	const fileItem = (e.target as HTMLElement).closest('.file-item');

	// Check if the clicked element is an <li> with the class 'file-item'
	if (fileItem) {
		const fileId = fileItem.getAttribute('data-file-id') || '';
		const userId = fileItem.getAttribute('data-user-id') || '';
		const boardId = fileItem.getAttribute('data-board-id') || '';

		console.log('[fileId]', fileId);
		console.log('[userId]', userId);
		console.log('[boardId]', boardId);

		fileSelectionBoard(boardId, fileId).then((response) => {
			renderFileList(response);
		});
	}
};

const uploadFile = function (e: Event) {
	e.preventDefault();
	const boardId = document.body.dataset.boardId!;
	const fileInput = document.querySelector<HTMLInputElement>('#fileInput')!;

	const file = fileInput.files?.[0];
	if (file) {
		uploadPdf(boardId, file).then((status) => {
			if (status) {
				getFilesOfBoard(boardId).then((response) => {
					setDefaultBoardSettings(response.files.length);
					renderFileList(response);
					fileInput.value = '';
				});
			}
		});
	}
};

const renderFileList = (response: { files: UserFile[]; users: BoardUser[] }) => {
	const fileListContainer = document.getElementById('file-list');
	if (!fileListContainer) return;

	fileListContainer.innerHTML = '';

	const boardId = document.body.dataset.boardId!;
	const currentUserId = document.body.dataset.userId!;

	response.files.forEach((file) => {
		const uploader = response.users.find((user) => user.id === file.uploadedBy.toString());
		const currentUser = response.users.find((user) => user.id === currentUserId);

		if (!uploader || !currentUser) return;

		const isSelected = currentUser.selectedFile?.fileId === file.fileId;

		// Create list item
		const listItem = document.createElement('li');
		listItem.className = 'file-item';
		if (isSelected) listItem.classList.add('default');
		listItem.dataset.fileId = file.fileId;
		listItem.dataset.userId = uploader.id;
		listItem.dataset.boardId = boardId;

		// Create file name element
		const fileNameElement = document.createElement('span');
		fileNameElement.className = 'file-name';
		fileNameElement.textContent = file.fileName;

		// Create uploader info element
		const uploadedByElement = document.createElement('span');
		uploadedByElement.className = 'uploaded-by';
		uploadedByElement.textContent = `Uploaded by: ${uploader.username}`;

		// Create container to display "Selected file" on a separate line
		const fileInfoContainer = document.createElement('div');
		fileInfoContainer.className = 'file-info';

		// Append file name and uploader info to the container
		fileInfoContainer.appendChild(fileNameElement);
		fileInfoContainer.appendChild(uploadedByElement);

		// Add "Selected file" text as a separate div for visibility
		if (isSelected) {
			const selectedTextElement = document.createElement('div');
			selectedTextElement.className = 'selected-file-text';
			selectedTextElement.textContent = 'Selected file';
			listItem.appendChild(selectedTextElement);
		}
		// Append fileInfoContainer to the list item
		listItem.appendChild(fileInfoContainer);
		fileListContainer.appendChild(listItem);
	});
};
