import { EmpathiseColour, EngineId, TopicAnalysis } from '../../../../src/commonTypes';
import { removeChildren } from './uiUtils';
import { resizeLongSuggestionsToFit, setEqualHeightForPairs } from './toolsUI';
import {
	empathiseResultsGenerated,
	lateralSliderMoved,
	TUTORIAL_EVENT_TYPES,
	tutorialEmitter,
} from '../tutorials/tutorialEvents';
import { ENGINE_TYPES } from '../../../../src/commonConstants';

const ENGINE_FILTERS: Record<EngineId, Array<{ key: string; label: string }>> = {
	Creative: [
		{ key: 'Filter0', label: 'None' },
		{ key: 'Filter1', label: 'Advertising' },
		{ key: 'Filter2', label: 'Brand' },
		{ key: 'Filter3', label: 'Media' },
	],
	Games: [
		{ key: 'Filter0', label: 'None' },
		{ key: 'Filter1', label: 'Mechanics' },
		{ key: 'Filter2', label: 'Interaction' },
	],
	Innovative: [
		{ key: 'Filter0', label: 'Product Design' },
		{ key: 'Filter1', label: 'Values' },
		{ key: 'Filter2', label: 'Customer Strategy' },
		{ key: 'Filter3', label: 'Innovation' },
		{ key: 'Filter4', label: 'Ethics' },
	],
	Create: [{ key: 'Filter0', label: 'None' }],
};

export const setupEmpathiseResults = (
	addSingleNode: (word: string, colour: EmpathiseColour, topics: string[]) => void,
	results: Array<{ color: EmpathiseColour; values: TopicAnalysis[] }>
) => {
	const container = document.querySelector('#empathise-container')!;
	const loadingIndicator = container.querySelector('.loading')!;

	loadingIndicator.classList.add('hidden');

	const lateralThinkingSlider = container.querySelector<HTMLInputElement>("input[type='range']")!;

	showEmpathiseResults(addSingleNode, results, parseInt(lateralThinkingSlider.value));

	lateralThinkingSlider.oninput = () => {
		showEmpathiseResults(addSingleNode, results, parseInt(lateralThinkingSlider.value));
		lateralSliderMoved(parseInt(lateralThinkingSlider.value));
	};
};

const getWordLi = (
	colour: EmpathiseColour,
	addSingleNode: (word: string, colour: EmpathiseColour, topics: string[]) => void,
	word: TopicAnalysis,
	empathiseResults: Array<{ color: EmpathiseColour; values: TopicAnalysis[] }>
) => {
	const wordLi = document.createElement('li');
	wordLi.innerHTML = word.key;
	wordLi.className = colour;
	wordLi.dataset.word = word.key;
	wordLi.onclick = reviseEmpathiseSuggestionsOnClick(
		wordLi,
		addSingleNode,
		word,
		colour,
		empathiseResults
	);
	return wordLi;
};

// Shows the empathise results in the UI and returns the suggestions to be added to the graph
const showEmpathiseResults = (
	addSingleNode: (word: string, colour: EmpathiseColour, topics: string[]) => void,
	empathiseResults: Array<{ color: EmpathiseColour; values: TopicAnalysis[] }>,
	empathiseSliderValue: number
) => {
	const pairsList = document.querySelector('#empathise-container .pairs-list')!;
	const pairsToShow: Array<{ color: EmpathiseColour; values: TopicAnalysis }> = [];
	removeChildren(pairsList); // remove any earlier pairs

	if (!empathiseResults.some(({ values }) => values.length)) {
		pairsList.innerHTML = '<p><em>No results</em></p>';
	}

	for (const results of empathiseResults) {
		const { color, values } = results;

		const index = Math.floor((values.length - 2) * (empathiseSliderValue / 100));
		const selectionOfValues = values.slice(index, index + 2);

		if (selectionOfValues.length > 0) {
			pairsList.append(getWordLi(color, addSingleNode, selectionOfValues[0], empathiseResults));
			pairsToShow.push({ color, values: selectionOfValues[0] });

			if (selectionOfValues.length > 1) {
				pairsList.append(getWordLi(color, addSingleNode, selectionOfValues[1], empathiseResults));
				pairsToShow.push({ color, values: selectionOfValues[1] });
			}
		}
	}

	// Whenever we're updating the empathise suggestions we listen to the event in the graph and update the currentSuggestions
	empathiseResultsGenerated(
		pairsToShow.map((pair) => ({
			color: pair.color,
			values: pair.values.key,
		}))
	);
	resizeLongSuggestionsToFit();
	setEqualHeightForPairs();
};

const reviseEmpathiseSuggestionsOnClick = (
	wordLi: HTMLLIElement,
	addSingleNode: (word: string, colour: EmpathiseColour, topics: string[]) => void,
	word: TopicAnalysis,
	colour: EmpathiseColour,
	empathiseResults: Array<{ color: EmpathiseColour; values: TopicAnalysis[] }>
) => {
	return () => {
		addSingleNode(word.key, colour, word.topics);

		const empathiseWordsForColour = empathiseResults.find((results) => results.color === colour)!;
		// Remove the placed word from the list
		const indexOfWord = empathiseWordsForColour.values.findIndex((item) => item.key === word.key);
		// const indexOfWord = empathiseWordsForColour.values.indexOf(word);
		empathiseWordsForColour.values.splice(indexOfWord, 1);

		let countAddedWords = 0;
		const targetNumberOfWords = 2;
		const wordsThatCouldBeAdded = [...empathiseWordsForColour.values];
		for (const w of wordsThatCouldBeAdded) {
			const currentSuggestions = Array.from(
				document.querySelectorAll<HTMLLIElement>('.pairs-list li')
			);
			if (!currentSuggestions.some((li) => li.innerText === w.key)) {
				wordLi.after(getWordLi(colour, addSingleNode, w, empathiseResults));
				countAddedWords += 1;
				if (countAddedWords === targetNumberOfWords) {
					break;
				}
			}
		}
		wordLi.remove();
	};
};

export const resetEmpathise = (fetchEmpathiseResults: () => Promise<void>) => {
	const container = document.querySelector('#empathise-container')!;
	const loadingIndicator = container.querySelector('.loading')!;
	const pairsList = container.querySelector('.pairs-list')!;
	const filterSelect = container.querySelector<HTMLSelectElement>('.filter')!;

	removeChildren(pairsList); // remove any earlier pairs
	removeChildren(filterSelect);

	let { engine } = document.body.dataset;

	const filterOptions = ENGINE_FILTERS[engine as EngineId];
	if (!filterOptions) {
		alert('Error: unknown engine type used for this board. Please contact us');
	}
	filterSelect.append(
		...filterOptions.map((filter) => {
			const selectOption = document.createElement('option');
			selectOption.value = filter.key;
			selectOption.innerText = filter.label;
			return selectOption;
		})
	);
	filterSelect.onchange = () => {
		fetchEmpathiseResults();
	};

	loadingIndicator.classList.remove('hidden');
};
