import * as tutorialEvents from '../tutorials/tutorialEvents';
import { closeAllPanels, removeChildren, showAlert } from './uiUtils';
import { copySelectedTextFromNotepadToPanel, openSynthesiseAiPanel } from './synthesiseAi';
import { MoodboardGraph } from '../graphs';

const notePadButton = document.querySelector<HTMLButtonElement>('#notepad-button');
const notepadContainer = document.querySelector<HTMLDivElement>('#notepad-container')!;
const addToMoodBoardButton = document.querySelector<HTMLButtonElement>(
	'#notepad-container #add-to-moodboard'
)!;

const getTextFromParentParagraph = (node: Node): string => {
	if (node.nodeName === 'P') {
		return node.textContent || '';
	} else {
		return getTextFromParentParagraph(node.parentNode!);
	}
};

const getCurrentLineFromSelection = (selection: Selection): string => {
	const node = selection.focusNode;
	return node ? getTextFromParentParagraph(node) : '';
};

const showTooltipForSynthesiseAi = () => {
	const aiTooltip = document.createElement('div');
	aiTooltip.classList.add('ai-tooltip');
	document.body.append(aiTooltip);

	const openAiButton = document.createElement('button');
	openAiButton.innerText = 'Open Synthesise AI';
	let openAiButtonListener: undefined | (() => void);
	const addLineToAiButton = document.createElement('button');
	addLineToAiButton.innerText = 'Add this line to Synthesise AI';
	let addLineToAiButtonListener: undefined | (() => void);
	const addSelectionToAiButton = document.createElement('button');
	addSelectionToAiButton.innerText = 'Add this selection to Synthesise AI';
	let addSelectionToAiButtonListener: undefined | (() => void);
	const addSelectionToMoodBoardButton = document.createElement('button');
	addSelectionToMoodBoardButton.innerText = 'Add this selection to board';
	let addSelectionToMoodBoardButtonListener: undefined | (() => void);

	function removeSlash(selectedNode: Node, slashIndex: number): void {
		const parentElement = selectedNode.parentElement;
		if (parentElement?.innerText.at(slashIndex) === '/') {
			parentElement.innerText =
				parentElement.innerText.slice(0, slashIndex) +
				parentElement.innerText.slice(slashIndex + 1);
		}
	}

	function removeAiTooltip(): void {
		setTimeout(() => {
			aiTooltip.style.display = 'none';
		}, 200);
	}

	function showAiTooltip({
		openAiButton,
		addLineToAiButton,
		addSelectionToAiButton,
		addSelectionToMoodBoardButton,
	}: {
		openAiButton?: HTMLButtonElement | undefined;
		addLineToAiButton?: HTMLButtonElement | undefined;
		addSelectionToAiButton?: HTMLButtonElement | undefined;
		addSelectionToMoodBoardButton?: HTMLButtonElement | undefined;
	}): void {
		setTimeout(() => {
			const selection = window.getSelection();
			const selectionIndex =
				(globalThis.neuroCreate.graph as MoodboardGraph).quill.getSelection()?.index || 0;

			const selectedNode = selection?.focusNode;
			if (selectedNode) {
				const slashIndex = selection.anchorOffset - 1;
				const selectedText = selection.focusNode?.textContent as string;
				removeChildren(aiTooltip);

				if (openAiButton) {
					aiTooltip.prepend(openAiButton);
					if (openAiButtonListener) {
						openAiButton.removeEventListener('click', openAiButtonListener);
					}
					openAiButtonListener = () => {
						openSynthesiseAiPanel();
						removeAiTooltip();
						removeSlash(selectedNode, slashIndex);
					};
					openAiButton.addEventListener('click', openAiButtonListener);
				}
				if (addLineToAiButton) {
					/* if the selected text is more than "/" character
					 * add "add this line..." button into  tooltip
					 */
					if (selectedText.trim().length > 0 && selectedText !== '/' && addLineToAiButton) {
						aiTooltip.append(addLineToAiButton);
					}
					if (addLineToAiButtonListener) {
						addLineToAiButton.removeEventListener('click', addLineToAiButtonListener);
					}
					addLineToAiButtonListener = () => {
						removeSlash(selectedNode, slashIndex);
						removeAiTooltip();
						openSynthesiseAiPanel(getCurrentLineFromSelection(selection));
					};
					addLineToAiButton.addEventListener('click', addLineToAiButtonListener);
				}
				if (addSelectionToAiButton) {
					aiTooltip.append(addSelectionToAiButton);
					if (addSelectionToAiButtonListener) {
						addSelectionToAiButton.removeEventListener('click', addSelectionToAiButtonListener);
					}
					addSelectionToAiButtonListener = () => {
						copySelectedTextFromNotepadToPanel();
						openSynthesiseAiPanel();
						removeAiTooltip();
						removeSlash(selectedNode, slashIndex);
					};
					addSelectionToAiButton.addEventListener('click', addSelectionToAiButtonListener);
				}

				if (addSelectionToMoodBoardButton) {
					aiTooltip.append(addSelectionToMoodBoardButton);

					if (addSelectionToMoodBoardButtonListener) {
						addSelectionToMoodBoardButton.removeEventListener(
							'click',
							addSelectionToMoodBoardButtonListener
						);
					}

					addSelectionToMoodBoardButtonListener = () => {
						addSelectedItemFromNotepadToMoodboard();
						removeAiTooltip();
						removeSlash(selectedNode, slashIndex);
					};

					addSelectionToMoodBoardButton.addEventListener(
						'click',
						addSelectionToMoodBoardButtonListener,
						{ once: true }
					);
				}

				if (aiTooltip.childElementCount > 0) {
					aiTooltip.style.display = 'flex';

					const range = selection?.getRangeAt(0);
					const rect = range.getBoundingClientRect();
					aiTooltip.style.top = `${rect.top + 20 > 640 ? 640 : rect.top + 20}px`;
					aiTooltip.style.left = `${
						rect.left + 5 > window.innerWidth - 250 ? window.innerWidth - 250 : rect.left + 5
					}px`;
				} else {
					aiTooltip.style.display = 'hidden';
				}

				// focus on the first button by default
				aiTooltip.querySelector('button')?.focus();

				// enable keyboard control within ai tooltip
				enableArrowControlsInAiTooltip(aiTooltip, selectionIndex);
			}
		}, 200);

		// remove tooltip when click outside of tooltip
		if (aiTooltip) {
			document.body.addEventListener('click', (event) => {
				if (
					!event.target ||
					event.target !== aiTooltip ||
					event.target !== openAiButton ||
					event.target !== addLineToAiButton ||
					event.target !== addSelectionToAiButton
				) {
					removeAiTooltip();
				}
			});
		}
	}

	if (addToMoodBoardButton) {
		addToMoodBoardButton.removeEventListener('click', addSelectedItemFromNotepadToMoodboard);
		addToMoodBoardButton.addEventListener('click', addSelectedItemFromNotepadToMoodboard);
	}

	notepadContainer.addEventListener('keydown', (event): void => {
		const synthesiseAiContainer = document.querySelector('#synthesise-ai-container');
		if (event.key === '/') {
			const selection = globalThis.neuroCreate.graph?.getSelection();
			const selectedPhrase = globalThis.neuroCreate.graph?.getCurrentlySelectedText();
			if (selectedPhrase && selectedPhrase.length > 1) {
				// add tooltip when text selection is made
				event.preventDefault();
				showAiTooltip({ addSelectionToAiButton, addSelectionToMoodBoardButton });
				aiTooltip.style.marginTop = `${16 * (selectedPhrase.length / 50)}px`;
			} else if (
				selection &&
				selection.focusNode?.nodeName === 'P' &&
				(selection.focusNode as HTMLParagraphElement).childNodes[0].nodeName === 'IMG'
			) {
				if (selection.isCollapsed === false) {
					// add tooltip when image selection is made
					event.preventDefault();
					const selectionHeight =
						(selection.focusNode?.childNodes[0] as HTMLImageElement)?.clientHeight || 0;
					showAiTooltip({ addSelectionToMoodBoardButton });
					aiTooltip.style.marginTop = `${selectionHeight - 16}px`;
				} else {
					event.preventDefault();
				}
			} else {
				// add tooltip when slash is typed without selection
				aiTooltip.style.marginTop = '0px';
				showAiTooltip({
					openAiButton: synthesiseAiContainer?.classList.contains('hidden')
						? openAiButton
						: undefined,
					addLineToAiButton,
				});
			}
		} else {
			removeAiTooltip();
		}
	});
};

const enableArrowControlsInAiTooltip = (
	aiTooltipContainer: HTMLDivElement,
	selectionIndex: number
): void => {
	aiTooltipContainer.querySelectorAll('button').forEach((button) => {
		button.addEventListener('keydown', (event) => {
			if (event.key === 'ArrowDown') {
				event.preventDefault();
				const nextButton = button.nextElementSibling as HTMLButtonElement;
				if (nextButton) {
					nextButton.focus();
				} else {
					aiTooltipContainer.querySelector('button')?.focus();
				}
			} else if (event.key === 'ArrowUp') {
				event.preventDefault();
				const previousButton = button.previousElementSibling as HTMLButtonElement;
				if (previousButton) {
					previousButton.focus();
				} else {
					(aiTooltipContainer.querySelector('button:last-of-type') as HTMLButtonElement).focus();
				}
			} else if (event.key === 'Enter') {
				button.click();
			} else {
				aiTooltipContainer.style.display = 'none';
				// move focus back to the position of the slash
				(globalThis.neuroCreate.graph as MoodboardGraph).quill.setSelection(selectionIndex, 0);
			}
		});
	});
};

export const openNotepad = () => {
	closeAllPanels('synthesise-ai-container');
	showTooltipForSynthesiseAi();
	notePadButton?.classList.add('buttonActive');
	notepadContainer?.classList.remove('hidden');
	tutorialEvents.openedNotepad();
};

export const closeNotepad = () => {
	notepadContainer?.classList.add('hidden');
	notePadButton?.classList.remove('buttonActive');
};

export const isNotepadOpen = () => {
	return notePadButton?.classList.contains('buttonActive');
};

if (notePadButton) {
	notePadButton.addEventListener('click', () => {
		if (isNotepadOpen()) {
			closeNotepad();
		} else {
			openNotepad();
		}
	});
}

const addSelectedItemFromNotepadToMoodboard = () => {
	if (globalThis.neuroCreate.graph) {
		const selectedPhrase = globalThis.neuroCreate.graph.getCurrentlySelectedText();
		const selection = globalThis.neuroCreate.graph.getSelection();

		let node;

		console.log('[selection]', selection);

		if (selectedPhrase && selectedPhrase.length > 1) {
			node = (globalThis.neuroCreate.graph as MoodboardGraph).addSelectionToMoodboard(
				selectedPhrase
			);
		} else if (
			selection &&
			selection.focusNode?.nodeName === 'P' &&
			(selection.focusNode as HTMLParagraphElement).childNodes[0].nodeName === 'IMG' &&
			selection.isCollapsed === false
		) {
			const url = new URL((selection.focusNode?.childNodes[0] as HTMLImageElement).src);
			node = (globalThis.neuroCreate.graph as MoodboardGraph).addSelectionToMoodboard({
				url: url.pathname,
				isVideo: false,
			});
		} else if (selection && !selection.isCollapsed && selection.focusNode) {
			const quill = (globalThis.neuroCreate.graph as MoodboardGraph).quill;
			const selectionIndex = quill.getSelection()?.index || 0;

			// Get the leaf at the current selection index.
			const [leaf, offset] = quill.getLeaf(selectionIndex);

			// Check if the leaf’s DOM node is an iframe (which represents a video embed).
			if (leaf && leaf.domNode && leaf.domNode.nodeName === 'IFRAME') {
				const videoUrl = (leaf.domNode as HTMLIFrameElement).src;
				node = (globalThis.neuroCreate.graph as MoodboardGraph).addSelectionToMoodboard({
					url: videoUrl,
					isVideo: true,
				});
			} else {
				showAlert('No video embed found at the selection.');
				return;
			}
		} else {
			showAlert('Please select an image or a phrase in the notepad to add to Synthesise board');
			return;
		}

		globalThis.neuroCreate.graph.updateActive(node);
	}
};
