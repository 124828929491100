// These types are used by both backend and frontend

import { AnalysisKey, RoleId } from './commonConstants';
import { ObjectId } from 'mongodb';

export type EngineId = 'Creative' | 'Games' | 'Innovative' | 'Create';

export type BaseUserColour = 'orange' | 'fuchsia' | 'red' | 'green';
export type AdditionalUserColour = 'dark' | 'violet' | 'yellow' | 'cyan';
export type AvailableColour = BaseUserColour | AdditionalUserColour | 'grey4' | 'blue' | 'IDEATE';

// Created by empathise tool, or link tool
export type EmpathiseColour =
	| 'FOREST'
	| 'RED'
	| 'FUSCHIA'
	| 'BLUE'
	| 'TAN'
	| 'SALMON'
	| 'GREENBLUE'
	| 'GREEN'
	| 'LINK'
	| 'IDEATE';

export const Perspectives: Record<EmpathiseColour, string> = {
	FOREST: 'Semantic association',
	TAN: 'Contextual association',
	RED: 'Negative association',
	FUSCHIA: 'Emotional association',
	BLUE: 'Co-occurence',
	SALMON: 'Semantic association',
	GREEN: 'Positive association',
	GREENBLUE: 'Creative association', // Not used
	LINK: 'Linking association', // Not used
	IDEATE: 'Creative association', // Not used
};

export type Point = {
	x: number;
	y: number;
};

export type VelocityObject = {
	vx?: number;
	vy?: number;
};

export type FixedPoint = {
	fx?: number;
	fy?: number;
};

export type LineSegment = {
	from: Point;
	to: Point;
};

export type MergedNode = {
	label?: string | null;
	nodeColour?: EmpathiseColour;
	mergedFrom?: Array<MergedNode>;
	linkedNodeIds?: Array<string>;
};

export type NCNode = Point &
	VelocityObject &
	FixedPoint & {
		uid: string;
		created: number;
		likes?: { [color: string]: boolean };
		style?: 'rect' | undefined; // Normally undefined, but can be set to 'rect' for nodes that are not circles
		colour: AvailableColour; // Usually determined by user who created the node
		nodeColour?: EmpathiseColour;
		label?: string | null;
		href?: string;
		src?: string;
		hidden?: boolean;
		dotted?: boolean;
		merged?: boolean;
		moving?: boolean;
		mergedFrom?: Array<MergedNode>;
		index?: number;
		parent?: {
			uid: string;
			label: string;
		};
		topics?: string[];
	};

export type NCGroup = {
	uid: string;
	nodes: Array<NCNode>;
	colour: AvailableColour;
	overrideColour?: string;
	active?: boolean;
	lock?: boolean;
};

export type NCGroupMinimal = {
	uid: string;
	nodes: Array<{ uid: string }>;
	colour: AvailableColour;
	overrideColour?: string;
	active?: boolean;
	lock?: boolean;
};

export type NCLink = {
	source: NCNode;
	target: NCNode;
	colour: AvailableColour;
};

export type NCLinkMinimal = {
	source: { uid: string };
	target: { uid: string };
	colour: AvailableColour;
};

export type NCCluster = {
	group: string;
	values: string[];
	likedNodes?: Array<string>;
	liked?: boolean;
};

export type GenerateTask =
	| 'haiku'
	| 'ai-haiku'
	| 'proverb'
	| 'brainstorm'
	| 'common'
	| 'persona'
	| 'audience'
	| 'motivations'
	| 'image-inspire'
	| 'image-synthesise';

export const SYNTHESISE_TASKS = [
	'audience',
	'brand-strategy',
	'product-idea',
	'product-brief',
	'game',
	'summary',
	'extract',
	'unite',
	'taglines',
	'brainstorm',
	'synopsis',
	'linkedinpost',
] as const;
export const SYNTHESISE_SUBTASKS = [
	'pestel-p',
	'pestel-e',
	'pestel-s',
	'pestel-t',
	'pestel-env',
	'pestel-l',
	'swot-s',
	'swot-w',
	'swot-o',
	'swot-t',
	'scamper-s',
	'scamper-c',
	'scamper-a',
	'scamper-m',
	'scamper-p',
	'scamper-e',
	'scamper-r',
] as const;
export type SynthesiseSubTask = (typeof SYNTHESISE_SUBTASKS)[number];
export type SynthesiseTask = (typeof SYNTHESISE_TASKS)[number];

export type SearchTask = 'search-image' | 'search-page' | 'article';

export type LikedItem = {
	type:
		| GenerateTask
		| SynthesiseTask
		| SynthesiseSubTask
		| SearchTask
		| 'node'
		| 'cluster'
		| 'linkedinpost';
	text?: string;
	title?: string;
	url?: string;
	topics?: string[];
};

export type NCGraphData = {
	template?: string;
	nodes: NCNode[];
	links: NCLinkMinimal[];
	groups?: NCGroupMinimal[];
	clusters?: NCCluster[];
	analyses?: AnalysisKey[];
	activeAnalysis?: AnalysisKey;
};

export type BoardUser = {
	id: string;
	username: string;
	email: string;
	isOnline: boolean;
	lastSeen: number | undefined; // timestamp
	activeNode?: string | undefined;
	settings: {
		ai_enabled: boolean;
		shown_inspire?: boolean;
		shown_ideate?: boolean;
		shown_synthesise?: boolean;
		presentationId?: string;
		used?: {
			spark?: boolean;
			empathise?: boolean;
			generate?: boolean;
			search?: boolean;
			link?: boolean;
			explore?: boolean;
		};
	};
	colour: string; // name of colour
};

export type TopicAnalysis = { key: string; topics: string[] };

export type UserSelection = {
	tool: 'spark' | 'empathise';
	words: string[];
	timestamp: number;
	topicMapping?: TopicAnalysis[];
};

export type PerUserData = {
	ideate?: { data: NCGraphData };
	ideateTeam?: { data: NCGraphData };
	moodboard?: { data: NCGraphData };
	inspire?: { data: NCGraphData }; // Currently not used
	userSelections?: Array<UserSelection>;
};

export type BoardUserField =
	| 'userOne'
	| 'userTwo'
	| 'userThree'
	| 'userFour'
	| 'userFive'
	| 'userSix'
	| 'userSeven'
	| 'userEight';

export type GraphMode = 'inspire' | 'ideate' | 'moodboard';

export type CapabilityKey =
	| 'create-board'
	| 'board-limit'
	| 'create-project'
	| 'time-limited'
	| 'collaborate-one'
	| 'collaborate-two'
	| 'collaborate-group'
	| 'collaborate-max'
	| 'export'
	| 'slides'
	| 'advanced-ai'
	| 'image-quota';

export type Capability = {
	key: CapabilityKey;
	name: string;
	implemented: boolean;
	type: 'boolean' | 'number';
	max?: number;
};

export interface IRole {
	key: string;
	name: string;
	capabilities: Array<{ capability: CapabilityKey; on: boolean; value?: number }>;
}

export type QueryableRole = IRole & {
	hasCapability: (key: CapabilityKey) => boolean;
	getCapabilityValue: (key: CapabilityKey) => number | undefined;
};

export type SubscriptionFrequency = 'DAY' | 'MONTH' | 'YEAR';

export type ISubscriptionPlan = {
	name: string;
	role: RoleId;
	frequency: SubscriptionFrequency;
	currency: 'GBP';
	offerPrice?: number;
	hasQuantity: boolean;
	hasSeatDiscounts?: boolean;
	hasFreeTrial?: boolean;
	hasMonthTrial?: boolean;
	paypalPlanId: string;
	createdDate: Date;
	isDisabled?: boolean;
};

export type ICustomPlan = {
	code: string;
	seats: number;
	price: number;
	role: string;
	frequency: SubscriptionFrequency;
	paypalPlanId: string;
	createdDate: Date;
	oneOffPeriod?: {
		months: number;
		monthlyPrice: number; // per member
	};
};

export type ClusterAnalysis = {
	name: string;
	category?: string;
	generate?: Array<GenerateTask>;
};

export type SelectableItem<T> = {
	value: T;
	selected: boolean;
	autogenerated?: boolean;
	cached?: boolean;
};

export type TargetAudience = {
	name: string;
	profession: string;
	age: string;
	needs: string;
	motivations: string;
};

export type TextResult = {
	text: string;
};

export type UrlResult = {
	url: string;
};

export type LinkResult = {
	url: string;
	text: string;
};

export interface PresentationInfo {
	_boardId: ObjectId;
	createdDate: Date;
	updatedDate: Date;
	_createdBy: ObjectId;
	title: string;
	date: string;
	vision?: string;
	change?: {
		start: string;
		end: string;
	};
	logistics?: {
		show: boolean;
		deliverables?: string;
		team?: string;
		currency?: string;
		budget?: string;
		deliveryDate?: string;
		firstReviewDate?: string;
		secondReviewDate?: string;
	};
	summary?: string;
	fullName?: string;
	personas: Array<SelectableItem<UserPersona>>;
	motivations: Array<SelectableItem<Motivations>>;
	brandStrategies: Array<SelectableItem<TextResult>>;
	productIdeas: Array<SelectableItem<TextResult>>;
	productBriefs: Array<SelectableItem<TextResult>>;
	targetAudiences: Array<SelectableItem<TargetAudience>>;
	images: Array<SelectableItem<UrlResult>>;
	photos: Array<SelectableItem<UrlResult>>;
	links: Array<SelectableItem<LinkResult>>;
	taglines: Array<SelectableItem<TextResult>>;
	haikus: Array<SelectableItem<TextResult>>;
	brainstorming: Array<SelectableItem<TextResult>>;
	commonIdeas: Array<SelectableItem<TextResult>>;
	conceptualTerritories: Array<
		SelectableItem<{
			phrase: string;
			summary?: string;
			results: Array<SelectableItem<LikedItem>>;
		}>
	>;
}

export type UserPersona = {
	theme: string;
	name: string;
	biography: string;
	goals: string;
	needs: string;
	painPoints: string;
	motivations: string;
	influences: string;
	scores: {
		tech: number;
		ambition: number;
		happiness: number;
	};
	imageUrl?: string;
};

export type Motivations = {
	theme: string;
	intro: string;
	outro: string;
	stakeholders: Array<{
		name: string;
		description: string;
		imageUrl?: string;
	}>;
};

export type SearchEntry = {
	type: 'article' | 'image';
	title?: string;
	url: string;
	snippet?: string;
	displayLink?: string;
};
