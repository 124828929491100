import { finishLoading } from '../lib/ui/uiUtils';
import { ENGINE_TYPES } from '../../../src/commonConstants';
import { EngineId } from '../../../src/commonTypes';

const SECOND_ANSWERS = {
	BRANDING: { name: 'Branding values' },
	TARGET: { name: 'Target Audience Trials' },
	TONE: { name: 'Tone-of-voice communication & marketing language' },
	CHARACTER: { name: 'Character Design' },
	STORY: { name: 'Story Arc & Copywriting' },
	PRODUCT: { name: 'Product/Strategy Brief Problem statement' },
	DESIGN: { name: 'Design-thinking & fact-finding research' },
	CULTURE: { name: 'Cultural Association & Emotional Nuance development' },
	BEHAVIOURAL: { name: 'Behavioural Motivation exploration' },
};

const FIRST_ANSWERS: {
	[id: string]: {
		name: string;
		games: Array<{ name: string }>;
		innovatives: Array<{ name: string }>;
	};
} = {
	CREATIVE: {
		name: 'Creative Strategist',
		games: [SECOND_ANSWERS.BEHAVIOURAL],
		innovatives: [SECOND_ANSWERS.PRODUCT, SECOND_ANSWERS.DESIGN],
	},
	COPYWRITER: {
		name: 'Copywriting taglines',
		games: [SECOND_ANSWERS.BEHAVIOURAL],
		innovatives: [SECOND_ANSWERS.PRODUCT, SECOND_ANSWERS.DESIGN],
	},
	WRITER: {
		name: 'Songwriter / Scriptwriter',
		games: [SECOND_ANSWERS.CHARACTER],
		innovatives: [SECOND_ANSWERS.PRODUCT, SECOND_ANSWERS.DESIGN],
	},
	PRODUCT_DESIGN: {
		name: 'Product Designer',
		games: [SECOND_ANSWERS.CHARACTER, SECOND_ANSWERS.BEHAVIOURAL],
		innovatives: [
			SECOND_ANSWERS.BRANDING,
			SECOND_ANSWERS.TARGET,
			SECOND_ANSWERS.STORY,
			SECOND_ANSWERS.PRODUCT,
			SECOND_ANSWERS.DESIGN,
		],
	},
	GAME: {
		name: 'Experience Designer',
		games: [
			SECOND_ANSWERS.BRANDING,
			SECOND_ANSWERS.TARGET,
			SECOND_ANSWERS.TONE,
			SECOND_ANSWERS.CHARACTER,
			SECOND_ANSWERS.STORY,
			SECOND_ANSWERS.BEHAVIOURAL,
		],
		innovatives: [SECOND_ANSWERS.PRODUCT, SECOND_ANSWERS.DESIGN],
	},
	DIRECTOR: {
		name: 'Concept & Art Director',
		games: [SECOND_ANSWERS.BEHAVIOURAL],
		innovatives: [SECOND_ANSWERS.PRODUCT, SECOND_ANSWERS.DESIGN],
	},
};

const pickEngine = (
	firstAnswerId: keyof typeof FIRST_ANSWERS,
	secondAnswerId: keyof typeof SECOND_ANSWERS
): EngineId => {
	if (FIRST_ANSWERS[firstAnswerId].games.includes(SECOND_ANSWERS[secondAnswerId])) {
		return ENGINE_TYPES.GAMES.id;
	} else if (FIRST_ANSWERS[firstAnswerId].innovatives.includes(SECOND_ANSWERS[secondAnswerId])) {
		return ENGINE_TYPES.INNOVATIVE.id;
	} else {
		return ENGINE_TYPES.CREATIVE.id;
	}
};

const newBoardForm = document.querySelector<HTMLFormElement>('.new-form #board-form');
if (newBoardForm) {
	const isTemplate = !newBoardForm.action.endsWith('/board/board');

	const handleEngineInfo = () => {
		const engineTooltip = document.querySelector<HTMLDivElement>('.form-recommend .tooltip')!;
		const engineFile = document.querySelector<HTMLDivElement>('.form-recommend .engine-file')!;
		document.querySelectorAll('.engine-section input[type=radio]').forEach((engineInput) => {
			const label = document.querySelector<HTMLLabelElement>(`label[for='${engineInput.id}']`);
			const tooltip = label!.dataset.tooltip;
			engineInput.addEventListener('change', (event: any) => {
				if (tooltip) {
					engineTooltip.innerText = tooltip;
					engineTooltip.style.opacity = '1';
				}

				const engineId: string = event.target.id;
				if (engineId === ENGINE_TYPES.CREATE.id) {
					engineFile.style.display = 'flex';
				} else {
					engineFile.style.display = 'none';
				}
			});
		});
	};
	handleEngineInfo();

	const checkedInputs = newBoardForm.querySelectorAll<HTMLInputElement>('input[checked]');
	checkedInputs.forEach((input) => {
		// Ensure HTML matches input state (because some browsers can save input state between page loads)
		input.checked = true;
	});

	const getInputAndLabel = (
		name: string,
		key: string,
		labelString: string
	): [HTMLInputElement, HTMLLabelElement] => {
		const input = document.createElement('input');
		input.type = 'radio';
		input.name = name;
		input.value = key;
		input.id = key;
		const label = document.createElement('label');
		label.innerHTML = labelString;
		label.htmlFor = key;
		return [input, label];
	};

	const firstQuestion = newBoardForm.querySelector<HTMLFieldSetElement>('.first-question');
	if (firstQuestion) {
		for (const key in FIRST_ANSWERS) {
			firstQuestion.append(...getInputAndLabel('q1', key, FIRST_ANSWERS[key].name));
		}

		const secondQuestion = newBoardForm.querySelector<HTMLFieldSetElement>('.second-question')!;
		for (const key in SECOND_ANSWERS) {
			secondQuestion.append(
				...getInputAndLabel('q2', key, SECOND_ANSWERS[key as keyof typeof SECOND_ANSWERS].name)
			);
		}

		const allInputs = newBoardForm.querySelectorAll<HTMLInputElement>(
			'.recommender-questions input, .left input'
		);
		allInputs.forEach((input) => {
			input.addEventListener('click', () => {
				const firstAnswer = document.querySelector<HTMLInputElement>(
					'.first-question input[type="radio"]:checked'
				);
				const secondAnswer = document.querySelector<HTMLInputElement>(
					'.second-question input[type="radio"]:checked'
				);

				if (firstAnswer) {
					if (firstAnswer.id === 'PRODUCT_DESIGN') {
						firstAnswer.classList.add(ENGINE_TYPES.INNOVATIVE.id);
					} else if (firstAnswer.id === 'GAME') {
						firstAnswer.classList.add(ENGINE_TYPES.GAMES.id);
					} else {
						firstAnswer.classList.add(ENGINE_TYPES.CREATIVE.id);
					}
					document.querySelector('.your-objective')!.classList.remove('hidden');

					if (secondAnswer) {
						// Both answers selected so select an engine
						const recommendedEngine = pickEngine(
							firstAnswer.id,
							secondAnswer.id as keyof typeof SECOND_ANSWERS
						);

						// Update colours to match engine
						firstAnswer.className = recommendedEngine;
						secondAnswer.className = recommendedEngine;

						const engineSuggestion = document.querySelector<HTMLParagraphElement>(
							'.recommender-questions .suggested-engine'
						)!;
						const engineName = engineSuggestion.querySelector<HTMLElement>('.engine-name')!;

						engineName.classList.replace(engineName.classList[1], recommendedEngine);
						engineName.innerHTML =
							ENGINE_TYPES[recommendedEngine.toUpperCase() as keyof typeof ENGINE_TYPES].name;

						const engineCheckbox = document.querySelector<HTMLInputElement>(
							`.engine-section input#${recommendedEngine}`
						)!;
						engineCheckbox.click();

						engineSuggestion.classList.remove('hidden');
						const enginePlaceholder = document.querySelector<HTMLParagraphElement>(
							'.form-container .form-recommend .title .default'
						)!;
						enginePlaceholder.style.display = 'none';
					}
				}
			});
		});

		if (firstQuestion.dataset.selected) {
			document.querySelector<HTMLInputElement>(`input#${firstQuestion.dataset.selected}`)?.click();
		}

		if (secondQuestion.dataset.selected) {
			document.querySelector<HTMLInputElement>(`input#${secondQuestion.dataset.selected}`)?.click();
		}

		if (isTemplate) {
			const inputsToDisable = [
				...allInputs,
				...newBoardForm.querySelectorAll<HTMLInputElement>(".right input[type='radio']"),
			];
			inputsToDisable.forEach((i) => {
				i.disabled = true;
			});
		}
	}
}

finishLoading();
