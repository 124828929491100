import Graph from './Graph';
import { getEvenlySpacedPoints } from './graphUtils';
import { showNonBlockingMessage } from '../ui/uiUtils';
import { splitTextIntoWords } from '../apiRequests';
import * as hints from '../tutorials/hints';
import * as tutorialEvents from '../tutorials/tutorialEvents';
import { startInspireTutorial } from '../tutorials/inspireTutorial';
import { isTutorialActive } from '../tutorials/tutorial';
import { getPointsSortedByClosestToCenter } from './geometryUtils';
import { closeAiContainers, updateInspireProgress } from '../ui/toolsUI';
import * as templateTutorial from '../tutorials/templateTutorial';
import {
	AvailableColour,
	NCGraphData,
	NCNode,
	BoardUser,
	GraphMode,
	UserSelection,
	NCGroup,
} from '../../../../src/commonTypes';
import { getLinksInvolvingNode } from './linkUtils';

class InspireGraph extends Graph {
	constructor(
		data: NCGraphData,
		boardUsers: Array<BoardUser>,
		userSelections: Array<UserSelection>,
		colourClass: AvailableColour,
		colourHex: string,
		automation: boolean
	) {
		super(data, boardUsers, userSelections, colourClass, colourHex, true, automation);

		this.appendMenuElements();

		updateInspireProgress(this);
	}

	getMenuOptions() {
		const activeNode = this.activeNode;

		return [
			{ label: 'Link', enabled: !activeNode?.src, requiresAi: true, shortText: true },
			{
				label: 'Generate',
				enabled: !activeNode?.src,
				requiresAi: true,
				longText: true,
				shortText: true,
			},
			{ label: 'Empathise', enabled: !activeNode?.src, requiresAi: true, shortText: true },
			{ label: 'Spark', enabled: !activeNode?.src, requiresAi: true, shortText: true },
			{ label: 'Like', enabled: true, longText: true, shortText: true },
			activeNode?.src
				? { label: 'View', enabled: !!activeNode?.src, shortText: true }
				: {
						label: 'Merge',
						enabled: this.isActiveNodeOwnedByCurrentUser() && !activeNode?.src,
						shortText: true,
					},
			{ label: 'Connect', enabled: true, longText: true, shortText: true },
			{ label: 'Search', enabled: true, longText: true, shortText: true },
			{ label: 'Explore', enabled: true, longText: true, requiresAi: true },
		];
	}

	get nodeRadius() {
		return 64;
	}

	/**
	 * Merge two nodes (or unmerged a previously merged node)
	 */
	merge(node: NCNode) {
		closeAiContainers();

		if (node.merged) {
			this.splitNodes(node, true);
		} else {
			this.startSelectingNodes('mergeFirstNode', [node]);

			showNonBlockingMessage(
				`This will merge "${
					node.label || ''
				}" to another node, combining into a phrase.<br> <strong>Select another node to merge with<strong>`,
				[
					{
						label: 'Cancel',
						onClick: () => {
							this.stopSelectingNodes();
						},
					},
				]
			);

			tutorialEvents.clickedMerge(node);
		}
	}

	/**
	 * Linker AI tool
	 */
	linker(node: NCNode) {
		if (!this.checkNodeReadyForAI(node, 'Link')) {
			return;
		}

		closeAiContainers();

		this.startSelectingNodes('linkingNodes', [node]);

		showNonBlockingMessage('Select one or more other nodes to find a linking concept', [
			{
				label: 'Cancel',
				onClick: () => {
					this.stopSelectingNodes();
				},
			},
		]);

		tutorialEvents.clickedLink(node);
		this.setToolUsed('link');
	}

	async toggleAi() {
		super.toggleAi();

		if (this.aiStatus) {
			if (await this.splitNodes()) {
				setTimeout(() => {
					this.zoomToFit();
				}, 250);
			}

			if (!this.templateId) {
				tutorialEvents.aiEnabled();
			} else if (this.templateId === 'branding') {
				tutorialEvents.aiEnabled();
			}
		}
	}

	autoSpark(): void {
		if (!isTutorialActive('inspire')) {
			if (!this.getHintsShown()) {
				// Automatically trigger spark on a few central nodes
				// Leave one node open and continue with hints
				const [demoNode1, demoNode2, demoNode3] = getPointsSortedByClosestToCenter(
					this.nodes.filter((n) => n.style !== 'rect')
				);

				const sparkNodeThen = async (nodeToSpark: NCNode, callback?: () => Promise<void>) => {
					if (nodeToSpark) {
						await this.spark(nodeToSpark, true);
						document.querySelector<HTMLLIElement>('.pairs-list li')?.click();

						if (callback) {
							await callback();
						}
					}
				};

				// eslint-disable-next-line @typescript-eslint/no-misused-promises
				setTimeout(async () => {
					await sparkNodeThen(demoNode1, async () => {
						await sparkNodeThen(demoNode2, () => {
							return sparkNodeThen(demoNode3);
						});

						// At least one node was sparked, so fire afterAutoSpark event
						setTimeout(() => {
							tutorialEvents.afterAutoSpark([demoNode1, demoNode2, demoNode3].filter((n) => n));
						}, 200);
					});
				}, 200);
			}
		}
	}

	async addWordsToBoard(wordsString: string, centralNodeString?: string) {
		const words = await splitTextIntoWords(this.boardId, wordsString, 'keywords');
		if (centralNodeString) {
			const cluster = {
				group: centralNodeString,
				values: words,
			};
			this.spawnCluster(cluster, { x: 0, y: 0, colourClass: this.userColourClass }, true);
		} else {
			const points = getEvenlySpacedPoints(words.length);
			words
				.filter((w) => w)
				.forEach((w, index) => {
					const { x, y } = points[index];
					this.spawnNewNode(
						{
							label: w,
							x,
							y,
						},
						null,
						true
					);
				});
		}
	}

	async addPdfContextToBoard(centralNodeString: string) {
		const cluster = {
			group: centralNodeString,
			values: [],
		};
		this.spawnCluster(cluster, { x: 0, y: 0, colourClass: this.userColourClass }, true);
	}

	updateGraphWithNewData(graphData: NCGraphData) {
		super.updateGraphWithNewData(graphData);

		updateInspireProgress(this);
	}

	areNodesColoured() {
		return this.isCollaborative;
	}

	isEditable() {
		return !this.activeNode || this.isActiveNodeOwnedByCurrentUser();
	}

	get graphMode(): GraphMode {
		return 'inspire';
	}

	startTutorial() {
		super.startTutorial();
		if (this.templateId) {
			templateTutorial.start(this.templateId, this, true);
		} else {
			startInspireTutorial();
		}
	}

	startHints() {
		super.startHints();
		if (this.nodes.length > 0) {
			this.setHintsShown();
		} else if (!this.currentUser.settings.shown_inspire) {
			hints.beginInspireHint();
		}
	}

	setHintsShown() {
		super.setHintsShown();
		this.currentUser.settings.shown_inspire = true;
		this.saveUser();
	}

	getHintsShown(): boolean {
		return this.currentUser.settings.shown_inspire || false;
	}

	simulateInteractions() {
		// We will add 50 nodes to the board automatically simulating the user's interactions
		// This is used for testing collaboration between users by running from multiple browsers
		// The nodes are added in a sequence to simulate the user's interactions
		// Tip: test with 2 users, using low latency throttling in the network tab to see how robust the collaboration is
		const words = [
			'one',
			'two',
			'three',
			'four',
			'five',
			'six',
			'seven',
			'eight',
			'nine',
			'ten',
			'eleven',
			'twelve',
			'thirteen',
			'fourteen',
			'fifteen',
			'sixteen',
			'seventeen',
			'eighteen',
			'nineteen',
			'twenty',
			'twenty-one',
			'twenty-two',
			'twenty-three',
			'twenty-four',
			'twenty-five',
			'twenty-six',
			'twenty-seven',
			'twenty-eight',
			'twenty-nine',
			'thirty',
			'thirty-one',
			'thirty-two',
			'thirty-three',
			'thirty-four',
			'thirty-five',
			'thirty-six',
			'thirty-seven',
			'thirty-eight',
			'thirty-nine',
			'forty',
			'forty-one',
			'forty-two',
			'forty-three',
			'forty-four',
			'forty-five',
			'forty-six',
			'forty-seven',
			'forty-eight',
			'forty-nine',
			'fifty',
		];
		const delay = 500;
		let i = 0;
		const columnSize = 10;
		let lastNode: NCNode | null = null;
		const addedNodes: Array<NCNode> = [];
		let hasAddedNodes = false;
		let group: NCGroup | null = null;
		const interval = setInterval(() => {
			if (!hasAddedNodes && i < words.length) {
				lastNode = this.spawnNewNode(
					{
						label: words[i],
						x: (this.currentUserIndex - 1) * 1400 + 200 * Math.floor(i / columnSize),
						y: (i % columnSize) * 200,
					},
					lastNode && i % columnSize !== 0 ? [lastNode] : null,
					true
				);
				addedNodes.push(lastNode);
				i++;
			} else {
				hasAddedNodes = true;
				if (lastNode) {
					group = this.createGroup([addedNodes[0], addedNodes[1]]);
					lastNode = null;
					i = 2;
				} else {
					if (i < columnSize && group) {
						group = this.addNodesToGroup(group, [addedNodes[i]]);
						i++;
					} else if (i < columnSize * 2) {
						this.connectNodes(addedNodes[i], addedNodes[i + columnSize]);
						i++;
					} else if (i < columnSize * 3) {
						if (group!.nodes.length > 5) {
							group = this.removeNodesFromGroup(group!, [group!.nodes[0]]);
						}
						this.updateNodeLabel(addedNodes[i], `${addedNodes[i].label}-updated`);
						i++;
					} else if (i < columnSize * 4) {
						this.delete(addedNodes[i]);
						i++;
					} else if (i < columnSize * 5) {
						getLinksInvolvingNode(addedNodes[i], this.links).forEach((l) => {
							this.removeLink(l);
						});
						i++;
					} else {
						this.removeGroup(group!, true);
						this.updateActive(addedNodes.at(-1)!);
						clearInterval(interval);
					}
				}
			}
		}, delay);
	}
}

export default InspireGraph;
