import { html, css, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { showAlert } from '../lib/ui/uiUtils';

@customElement('video-generator-with-quota')
export class VideoGeneratorWithQuota extends LitElement {
	@property({ type: String })
	align: string = 'right';

	@property({ type: Boolean })
	isQuotaOnRight: boolean = false;

	@property({ type: Number })
	cost: number = 1;

	@property({ type: Number })
	get max() {
		return parseInt(document.body.dataset.videoCreditsTotal || '0');
	}

	@property({ type: Number })
	get used() {
		return parseInt(document.body.dataset.videoCreditsUsed || '0');
	}

	set used(value: number) {
		document.body.dataset.videoCreditsUsed = value.toString();
	}

	@property({ type: Boolean })
	hideQuota: boolean = false;

	@property({ type: Boolean })
	get isQuotaHidden() {
		return this.hideQuota || this.max === 0;
	}

	@property({ type: Boolean })
	get isQuotaExceeded() {
		return this.max && this.used >= this.max;
	}

	@property({ type: Boolean })
	get quotaWouldBeExceeded() {
		return this.max && this.used + this.cost > this.max;
	}

	@property({ type: Boolean })
	isDisabled: boolean = false;

	@property({ type: Boolean })
	hideBackground: boolean = false;

	@property({ type: Function })
	onClick: () => void = () => {};

	static styles = css`
		.quota-wrapper {
			max-width: 165px;
			margin: -0.5em 2em 0.5em 2em;
			padding: 0.8em 0.5em;
			background-color: #f8f8f8;
			text-align: center;
			border-radius: 0.5em;
		}

		.quota-wrapper.hideBackground {
			background-color: transparent;
			padding-top: 0;
			margin: -1em 2em 0.1em;
		}

		.quota-wrapper.right {
			float: right;
			clear: right;
		}

		.quota-wrapper.left {
			float: left;
			margin: 0 0 0.5em;
		}

		.quota-wrapper.center {
			margin: -0.2em auto 0.5em;
		}

		.quota-wrapper.isRight {
			max-width: 200px;
			display: flex;
			gap: 1rem;
		}

		.quota {
			display: block;
			font-size: 0.8em;
			color: var(--grey3);
			margin-top: 0.5em;
		}

		.quota.hidden {
			display: none;
		}

		.quota .link {
			color: var(--grey3);
		}

		.hideBackground .quota {
			margin-top: 0;
		}

		.generate-video-button {
			background-color: #fff;
			border: 1px solid var(--grey3);
			border-radius: 0.5em;
			font-size: small;
			padding: 0.5em;
			cursor: pointer;
		}

		.generate-video-button:hover,
		.generate-video-button:focus {
			background-color: var(--blue);
			color: #fff;
		}

		.generate-video-button.disabled {
			border: 1px solid var(--grey5);
			background-color: var(--grey5);
			color: #a1a1a1 !important;
		}
		.generate-video-button.disabled:hover,
		.generate-video-button.disabled:focus {
			background-color: var(--grey5) !important;
		}

		.hideBackground .generate-video-button {
			padding: 0.1em 0.5em;
			font-size: smaller;
		}
	`;

	validate = () => {
		if (this.isQuotaExceeded) {
			showAlert(
				"You currently have no credits remaining. See <a href='/settings/quotas' target='_blank' rel='noreferrer noopener' title='Quota Settings'>settings</a> for more details."
			);
			return false;
		} else if (this.quotaWouldBeExceeded) {
			showAlert(
				"You do not have enough credits left for this. See <a href='/settings/quotas' target='_blank' rel='noreferrer noopener' title='Quota Settings'>settings</a> for more details."
			);
			return false;
		} else if (this.isDisabled) {
			showAlert('Please select an option to generate an video.');
			return false;
		}
		return true;
	};

	handleClick = () => {
		if (this.validate()) {
			this.onClick();
			this.used = this.used + this.cost;
			document.querySelectorAll('video-generator-with-quota').forEach((el) => {
				el.requestUpdate();
			});
		}
	};

	render() {
		return html` <div
			class="quota-wrapper ${this.align} ${this.isQuotaOnRight ? 'isRight' : ''} ${this
				.hideBackground
				? 'hideBackground'
				: ''}"
		>
			<button
				class="generate-video-button ${this.isDisabled || this.quotaWouldBeExceeded
					? 'disabled'
					: ''}"
				@click="${this.handleClick}"
			>
				Generate Video${this.cost > 1 ? `s (${this.cost} credits)` : ''}
			</button>
			<div class="quota ${this.isQuotaHidden ? 'hidden' : ''}">
				<a
					class="link"
					href="/settings/quotas"
					target="_blank"
					rel="noreferrer noopener"
					title="Quotas Settings"
				>
					<span class="value">${this.max - this.used}</span> of
					<span class="max">${this.max}</span> credits remaining
				</a>
			</div>
		</div>`;
	}
}

declare global {
	interface HTMLElementTagNameMap {
		'video-generator-with-quota': VideoGeneratorWithQuota;
	}
}
