import { removeEl, removeNoDeleteAnimation } from './graphUtils';
import * as d3 from 'd3';
import { generateNodeId } from './graphNodeUtils';
import {
	AvailableColour,
	EmpathiseColour,
	NCCluster,
	NCGraphData,
	NCNode,
} from '../../../../src/commonTypes';
import { GraphProps } from '../../types';

export const getClusterRadius = (cluster: NCCluster): number => {
	return 100 + cluster.values.length * 7;
};

export const clusterKeyFn = (d: NCCluster, i: number) => i;

export const createClusterNodes = (
	cluster: NCCluster,
	clusterProps: { x: number; y: number; colourClass: AvailableColour; radius?: number },
	userColourClass: AvailableColour,
	ideate?: NCGraphData
): { centralNode: NCNode; linkedNodes: Array<NCNode> } => {
	const centralNode = {
		uid: generateNodeId(),
		created: new Date().getTime(),
		x: clusterProps.x,
		y: clusterProps.y,
		label: cluster.group,
		colour: clusterProps.colourClass,
	};

	const linkedNodes = [] as Array<NCNode>;
	const radiansPerNode = (2 * Math.PI) / cluster.values.length;
	const clusterRadius = clusterProps.radius || getClusterRadius(cluster);
	cluster.values.forEach((label, index) => {
		const likes = {} as { [color: string]: boolean };
		if (cluster.likedNodes?.includes(label)) {
			likes[userColourClass] = true;
		}
		const newNode = {
			uid: generateNodeId(),
			created: new Date().getTime(),
			x: clusterRadius * Math.cos(radiansPerNode * index) + clusterProps.x,
			y: clusterRadius * Math.sin(radiansPerNode * index) + clusterProps.y,
			label,
			colour: clusterProps.colourClass,
			parent: centralNode,
			likes,
			nodeColour: ideate?.nodes.some(
				(node) => node.label === label || node.topics?.some((topic) => topic.trim() === label)
			)
				? ('IDEATE' as EmpathiseColour)
				: undefined,
		};
		linkedNodes.push(newNode);
	});
	return { centralNode, linkedNodes };
};

export const createClusters = (
	baseElement: d3.Selection<SVGGElement, null, HTMLElement, null>,
	clusters: Array<NCCluster>,
	enterCluster: (
		elem: d3.Selection<d3.EnterElement, NCCluster, SVGGElement, null>
	) => d3.Selection<SVGGElement, NCCluster, SVGGElement, null>,
	graphProps: GraphProps
) =>
	baseElement
		.append('g')
		.attr('class', 'clusters')
		.selectAll<SVGGElement, NCCluster>('g')
		.data(clusters, clusterKeyFn)
		.join<SVGGElement>(
			enterCluster,
			updateCluster,
			graphProps.isDeleteAnimated ? removeEl : removeNoDeleteAnimation
		);

export const appendClusterCircle = (
	cluster: d3.Selection<SVGGElement, NCCluster, SVGGElement, null>
): d3.Selection<SVGCircleElement, NCCluster, SVGGElement, null> => {
	const circle = cluster
		.append('circle')
		.attr('class', 'cluster')
		.attr('stroke', '#91909A')
		.attr('stroke-width', '2')
		.attr('stroke-dasharray', '2,2')
		.attr('fill', 'rgba(0,0,0,0)');

	cluster.append('circle').attr('r', 18).attr('class', 'cluster-heart-circle').attr('fill', '#fff');

	cluster.append('image').attr('class', 'cluster-heart').attr('width', 24).attr('height', 24);

	updateCluster(cluster);

	return circle;
};

export const updateCluster = (cluster: d3.Selection<SVGGElement, NCCluster, SVGGElement, null>) => {
	cluster.attr('data-cluster', (d) => d.group);

	cluster
		.select('image')
		.attr('href', (d) => `/assets/icon/board/heart-${d.liked ? 'full' : 'empty'}.png`)
		.attr('x', (d) => 85 + d.values.length * 5)
		.attr('y', (d) => -150 - d.values.length * 5);

	cluster.select('.cluster').attr('r', (d) => 170 + d.values.length * 7);

	cluster
		.select('.cluster-heart-circle')
		.attr('cx', (d) => 96 + d.values.length * 5)
		.attr('cy', (d) => -139 - d.values.length * 5);

	return cluster;
};
