import introJs from 'intro.js';
import { NCNode, GraphMode } from '../../../../src/commonTypes';
import { clearNonBlockingMessage, showNonBlockingMessage } from '../ui/uiUtils';
import { WINDOW_BREAKPOINTS } from '../constants';
import { disableChatbot, enableChatbot } from './tutorialEvents';

const tutorialButton = document.querySelector<HTMLButtonElement>('#tutorial')!;
if (tutorialButton) {
	tutorialButton.addEventListener('click', () => {
		if (globalThis.neuroCreate.graph) {
			globalThis.neuroCreate.graph.startTutorial();
		}
	});
}

export const deactivateTutorialButton = () => {
	if (tutorialButton.className === 'tutorialActive') {
		tutorialButton.classList.remove('tutorialActive');
	}
};

// Allows any element on page to be clicked (including SVG)
const enableClickthrough = () => {
	document.body.classList.add('enable-tutorial-clickthrough');
};

// Reverts to default Intro.js behavior, can only click on highlighted element
const disableClickthrough = () => {
	document.body.classList.remove('enable-tutorial-clickthrough');
};

export type IntroJs = ReturnType<typeof introJs>;

type StepExtra = {
	completeStep?: (target: HTMLOrSVGElement) => Promise<void>;
};

let timeout: NodeJS.Timeout | undefined;
let tutorial: IntroJs | undefined;
// options must include 'steps'
export const startTutorial = (
	options: Partial<IntroJs['_options']> & { steps: Array<StepExtra> },
	handlers: {
		onexit?: () => void;
		onafterchange?: (target: HTMLElement) => void;
		onbeforechange?: (target: HTMLElement) => boolean | Promise<boolean>;
	} = {}
) => {
	disableChatbot();
	// Call .exit(), fixes bug where buttons are sometimes hidden
	introJs().exit(true);
	tutorial = introJs().setOptions({
		showBullets: Boolean(options.steps && options.steps.length > 1),
		showButtons: true,
		keyboardNavigation: true,
		exitOnOverlayClick: false,
		disableInteraction: true,
		scrollToElement: false,
		...options,
	});

	if (options.disableInteraction === false) {
		enableClickthrough();
	} else {
		disableClickthrough();
	}

	const { onexit, onafterchange, onbeforechange } = handlers;
	if (onexit) {
		tutorial = tutorial.onexit(onexit);
	}
	tutorial = tutorial.onafterchange((target) => {
		if (onafterchange) {
			onafterchange(target);
		}
		if (globalThis.neuroCreate.automateTutorial) {
			// Clear timeout as it may have been set but not yet triggered by a previous step
			timeout && clearTimeout(timeout);
			const currentStepIndex = tutorial?.currentStep();
			if (currentStepIndex !== undefined) {
				const currentStep = options.steps[currentStepIndex];
				if (currentStep.completeStep) {
					currentStep.completeStep(target).then(() => {
						timeout = setTimeout(() => {
							tutorial?.nextStep();
						}, 1000);
					});
				}
			}
		}
	});
	tutorial = tutorial.onbeforechange((target) => {
		if (target) {
			const scrollOptions: ScrollIntoViewOptions = { behavior: 'smooth', block: 'center' };
			console.log('scrollTo', target);
			if (true) {
				console.log('scrolling');
				// Scroll to target if it is not in view
				// https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoViewIfNeeded#browser_compatibility
				// @ts-expect-error This is not a standard method, but it is available in many browsers
				target.scrollIntoViewIfNeeded
					? // @ts-expect-error This is not a standard method, but it is available in many browsers
						// eslint-disable-next-line @typescript-eslint/no-unsafe-call
						target.scrollIntoViewIfNeeded(scrollOptions)
					: target.scrollIntoView(scrollOptions);
			}
		}
		if (onbeforechange) {
			return onbeforechange(target);
		}
		return true;
	});
	tutorial.start();
};

export const isTutorialActive = (graphMode: GraphMode, templateId?: string) => {
	if (templateId) {
		return Boolean(
			globalThis.neuroCreate.graph && globalThis.neuroCreate.graph.graphMode === graphMode
		);
	} else {
		return Boolean(
			globalThis.neuroCreate.graph &&
				globalThis.neuroCreate.graph.graphMode === graphMode &&
				tutorialButton.className === 'tutorialActive'
		);
	}
};

export const exitTutorial = () => {
	if (tutorial) {
		tutorial.exit(true);
		tutorial = undefined;
		clearNonBlockingMessage();
		console.log("Exiting tutorial");
		enableChatbot();
	}
};

export const endTutorial = () => {
	tutorialButton.classList.remove('tutorialActive');

	// Close expanded menu to ensure it will be expanded when tutorial is restarted
	closeExpandedMenuAfterTutorial(
		document.querySelector<HTMLButtonElement>('#local-menu .expand-menu')!,
		document.querySelector<HTMLButtonElement>('#local-menu .collapse-menu')!
	);
	console.log("Ending tutorial");
	enableChatbot();
	disableClickthrough();
};

export const updatePosition = () => {
	if (tutorial) {
		tutorial.refresh();
	}
};

export const addTextWithTypingEffect = (
	reference: NCNode | HTMLTextAreaElement,
	textToAdd: string
) => {
	if (globalThis.neuroCreate.graph) {
		if (textToAdd.length) {
			if ((reference as NCNode).uid) {
				// Reference is a node
				const referenceNode = reference as NCNode;
				const currentNode = globalThis.neuroCreate.graph.getNode(referenceNode.uid)!;
				currentNode.label = currentNode.label
					? `${currentNode.label}${textToAdd.substring(0, 1)}`
					: textToAdd.substring(0, 1);
				if (globalThis.neuroCreate.saveNodes) {
					globalThis.neuroCreate.saveNodes.update([currentNode]);
				}
				setTimeout(
					() => {
						addTextWithTypingEffect(referenceNode, textToAdd.substring(1, textToAdd.length));
					},
					20 + Math.random() * 80
				);
			} else {
				// Reference is a TextArea
				const referenceTextArea = reference as HTMLTextAreaElement;
				referenceTextArea.value = referenceTextArea.value
					? `${referenceTextArea.value}${textToAdd.substring(0, 1)}`
					: textToAdd.substring(0, 1);
				setTimeout(
					() => {
						addTextWithTypingEffect(referenceTextArea, textToAdd.substring(1, textToAdd.length));
					},
					20 + Math.random() * 80
				);
			}
		}
	}
};

export const onExitShowInstruction = (instruction: string) => {
	return {
		onexit: () => {
			showNonBlockingMessage(`&#x27A1;&#xFE0F; ${instruction}`);
		},
	};
};

export const openCollapsedMenuForTutorial = (
	tutorialTarget: HTMLElement,
	expandButton: HTMLButtonElement
) => {
	if (!tutorialTarget) {
		return;
	}
	const isMenuCollapsed = tutorialTarget.classList.contains('collapsed');
	const isMenuActive = expandButton.classList.contains('active');
	if (isMenuCollapsed && !isMenuActive) {
		expandButton.click();
		updatePosition();
		setTimeout(() => {
			updatePosition();
		}, 200);
	}
};

export const closeExpandedMenuAfterTutorial = (
	expandButton: HTMLButtonElement,
	collapseButton?: HTMLButtonElement
) => {
	// toggle expand menu if it is expanded
	if (window.innerWidth < WINDOW_BREAKPOINTS.laptop && expandButton.classList.contains('active')) {
		expandButton.click();
	}
	if (window.innerWidth >= WINDOW_BREAKPOINTS.laptop && collapseButton) {
		collapseButton.click();
	}
};
