import Pino from 'pino';

import { showTooltip } from './uiUtils';
import * as tutorialEvents from '../tutorials/tutorialEvents';

const pino = Pino({
	browser: {
		transmit: {
			level: 'error',
			send: function (level: string, logEvent: Pino.LogEvent & { stacktrace?: string }) {
				const stacktrace = Error().stack;
				let body = JSON.stringify({
					ts: logEvent.ts,
					messages: logEvent.messages,
					stacktrace,
				});
				if (body.length > 10000) {
					body = JSON.stringify({
						ts: logEvent.ts,
						// eslint-disable-next-line @typescript-eslint/no-unsafe-return
						messages: logEvent.messages.map((m) => ({ ...m, data: undefined })),
						stacktrace,
					});
				}

				fetch('/api/log', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body,
				});
			},
		},
	},
});

const addCloseButtonsToPanels = () => {
	const panels = document.querySelectorAll('.panel');
	panels.forEach((panel) => {
		const closeButton = document.createElement('button');
		const icon = document.createElement('img');
		closeButton.classList.add('close-panel');
		icon.src = '/assets/icon/board/close.png';
		closeButton.appendChild(icon);
		panel.appendChild(closeButton);

		closeButton.addEventListener('click', () => {
			document.querySelectorAll('#top-menu-wrapper button.buttonActive').forEach((button) => {
				if (button !== document.getElementById('timerButton')) {
					button.classList.remove('buttonActive');
				}
			});
			closeButton.closest('div.panel')?.classList.add('hidden');
			document.querySelector('#backdrop')?.classList.add('hidden');

			if (panel.id === 'invite-container') {
				tutorialEvents.closedInvite();
			}
			tutorialEvents.closedTool(panel.id);
		});
	});
};

const addInfoTooltips = () => {
	// show tooltip when user clicks the info icon
	const infoIcons = document.querySelectorAll<HTMLElement>('.info');
	infoIcons.forEach((icon) => {
		icon.addEventListener('click', (e) => {
			e.preventDefault();
			if (icon.dataset['tooltip']) {
				showTooltip(icon.dataset['tooltip']);
			}
		});
	});
};

const initBoardUserList = () => {
	const boardUserList = document.querySelector<HTMLUListElement>('.board-user-icon-list');
	if (boardUserList) {
		const boardUserListItems = boardUserList.querySelectorAll('li');
		const listWidth = 24;
		const increaseWidth = 12;

		// Make the width of board user list wider
		// depending on how many users are there
		window.addEventListener('load', () => {
			boardUserList.style.width = `${listWidth + boardUserListItems.length * increaseWidth}px`;
		});
	}
};

const createLandscapeWarning = () => {
	if (document.body.dataset.supportAllScreens) {
		return;
	}
	const landscapeWarning = document.createElement('div');
	landscapeWarning.setAttribute('id', 'landscape-warning');

	const logo = document.createElement('img');
	logo.setAttribute('src', '/assets/flowcreate_icon.png');

	const warningP = document.createElement('p');
	warningP.innerHTML =
		'<strong>Please increase window size or turn your device to landscape mode</strong><br><br> FlowCreate requires a wider screen<br><br>';
	landscapeWarning.append(logo, warningP);

	document.body.appendChild(landscapeWarning);
};

export const initGlobalUI = () => {
	addInfoTooltips();
	addCloseButtonsToPanels();
	initBoardUserList();
	createLandscapeWarning();

	console.error = pino.error.bind(pino);
};
