import { EventEmitter } from 'events';

import { ClusterProps } from '../../types';
import { EmpathiseColour, NCCluster, NCNode, SearchEntry } from '../../../../src/commonTypes';

export const tutorialEmitter = new EventEmitter();

export const TUTORIAL_EVENT_TYPES = {
	createdNode: 'createdNode',
	openedNodeMenu: 'openedNodeMenu',
	aiEnabled: 'aiEnabled',
	openedProjectVisionPanel: 'openedProjectVisionPanel',
	openedAddToBoardPanel: 'openedAddToBoardPanel',
	addedWordsFromPanel: 'addedWordsFromPanel',
	afterAutoSpark: 'afterAutoSpark',

	// Tool selection events
	clickedSpark: 'clickedSpark',
	clickedEmpathise: 'clickedEmpathise',
	clickedGenerate: 'clickedGenerate',
	clickedSynthesise: 'clickedSynthesise',
	clickedHaikus: 'clickedHaikus',
	clickedAIHaikus: 'clickedAIHaikus',
	clickedGenerateImage: 'clickedGenerateImage',
	clickedGenerateImageResult: 'clickedGenerateImageResult',
	clickedBrainstorm: 'clickedBrainstorm',
	clickedBrainstormResults: 'clickedBrainstormResults',
	clickedCommonIdeas: 'clickedCommonIdeas',
	clickedCommonIdeasResults: 'clickedCommonIdeasResults',
	clickedTaglines: 'clickedTaglines',
	clickedGenerateUserPersona: 'clickedGenerateUserPersona',
	clickedGenerateMotivations: 'clickedGenerateMotivations',
	clickedSearch: 'clickedSearch',
	clickedLink: 'clickedLink',
	clickedMerge: 'clickedMerge',
	clickedConnect: 'clickedConnect',
	clickedGeneratePresentation: 'clickedGeneratePresentation',
	lateralSliderMoved: 'lateralSliderMoved',

	// Ideate board
	clickedPersonalityAnalysis: 'clickedPersonalityAnalysis',
	clickedArchetypeAnalysis: 'clickedArchetypeAnalysis',
	clickedPlotsAnalysis: 'clickedPlotsAnalysis',
	clickedVisualAnalysis: 'clickedVisualAnalysis',
	clickedThemesAnalysis: 'clickedThemesAnalysis',
	clickedRandomAnalysis: 'clickedRandomAnalysis',
	clickedGreekGodsAnalysis: 'clickedGreekGodsAnalysis',
	clickedMotivationsAnalysis: 'clickedMotivationsAnalysis',
	clickedMotivationChallengesAnalysis: 'clickedMotivationChallengesAnalysis',
	clickedMotivationHarmonyAnalysis: 'clickedMotivationHarmonyAnalysis',
	clickedMotivationsStimulationAnalysis: 'clickedMotivationsStimulationAnalysis',
	clickedMotivationsNoveltyAnalysis: 'clickedMotivationsNoveltyAnalysis',
	clickedMotivationsThreatAnalysis: 'clickedMotivationsThreatAnalysis',
	clickedUnlike: 'clickedUnlike',
	
	openedIdeaBox: 'openedIdeaBox',
	openedInvite: 'openedInvite',
	closedInvite: 'closedInvite',
	invitedCollaborator: 'invitedCollaborator',
	selectedNode: 'selectedNode',
	addedTwoNodes: 'addedTwoNodes',
	addedSingleNode: 'addedSingleNode',
	addedCluster: 'addedCluster',
	likedNode: 'likedNode',
	likedResult: 'likedResult',
	likedCluster: 'likedCluster',
	mergedNodes: 'mergedNodes',
	connectedNodes: 'connectedNodes',
	updatedLabel: 'updatedLabel',
	afterNewAnalysis: 'afterNewAnalysis',
	openedNotepad: 'openedNotepad',
	addedToNote: 'addedToNote',
	selectingItems: 'selectingItems',
	doneSelectingItems: 'doneSelectingItems',
	synthesiseResult: 'synthesiseResult',
	openedExport: 'openedExport',
	openedPresentationPreview: 'openedPresentationPreview',
	userPersonasGenerated: 'userPersonasGenerated',
	stakeholderMotivationsGenerated: 'stakeholderMotivationsGenerated',
	linkedInPostGenerated: 'linkedInPostGenerated',
	brandStrategyGenerated: 'brandStrategyGenerated',
	closedTool: 'closedTool',
	
	// Chatbot related events
	disableChatbot: 'disableChatbot',
	enableChatbot: 'enableChatbot',
	chatbotStarted: 'chatbotStarted',
	completedChatbotSequence: 'completedChatbotSequence',
	userDecisionMade: 'userDecisionMade',
	switchBoard: 'switchBoard',
	
	// Generated results from AI tools
	sparkResultsGenerated: 'sparkResultsGenerated',
	empathiseResultsGenerated: 'empathiseResultsGenerated',
	brainstormResultsGenerated: 'brainstormResultsGenerated',
	commonIdeasResultsGenerated: 'commonIdeasResultsGenerated',
	haikuResultsGenerated: 'haikuResultsGenerated',
	aiHaikuResultsGenerated: 'aiHaikuResultsGenerated',
	taglineResultsGenerated: 'taglineResultsGenerated',
	searchResultsGenerated: 'searchResultsGenerated',
	personaResultsGenerated: 'personaResultsGenerated',
	imageInspireGenerated: 'imageInspireGenerated',
	videoInspireGenerated: 'videoInspireGenerated',
	imageSynthesiseGenerated: 'imageSynthesiseGenerated',
	audiencesGenerated: 'audiencesGenerated',
};

export const userCreatedNode = (node: NCNode) => {
	setTimeout(() => {
		tutorialEmitter.emit('createdNode', { node });
	}, 400);
};

export const openedNodeMenu = (node: NCNode) => {
	tutorialEmitter.emit('openedNodeMenu', { node });
};

export const aiEnabled = () => {
	tutorialEmitter.emit('aiEnabled');
};

export const clickedSpark = (node: NCNode) => {
	tutorialEmitter.emit('clickedSpark', { node });
};

export const clickedEmpathise = (node: NCNode) => {
	tutorialEmitter.emit('clickedEmpathise', { node });
};

export const clickedGenerate = (node: NCNode) => {
	tutorialEmitter.emit('clickedGenerate', { node });
};

export const clickedSynthesise = (node: NCNode) => {
	tutorialEmitter.emit('clickedSynthesise', { node });
};

export const clickedHaikus = () => {
	tutorialEmitter.emit('clickedHaikus', {});
};

export const clickedGenerateUserPersona = () => {
	tutorialEmitter.emit('clickedGenerateUserPersona', {});
};

export const clickedGenerateImage = () => {
	tutorialEmitter.emit('clickedGenerateImage', {});
};

export const clickedGenerateVideo = () => {
	tutorialEmitter.emit('clickedGenerateVideo', {});
};

export const clickedGenerateImageResult = () => {
	tutorialEmitter.emit('clickedGenerateImageResult', {});
};

export const clickedGenerateVideoResult = () => {
	tutorialEmitter.emit('clickedGenerateVideoResult', {});
};

export const clickedBrainstorm = () => {
	tutorialEmitter.emit('clickedBrainstorm', {});
};

export const clickedBrainstormResults = (selectedNodes: Array<NCNode>) => {
	tutorialEmitter.emit('clickedBrainstormResults', { selectedNodes });
};

export const clickedCommonIdeas = () => {
	tutorialEmitter.emit('clickedCommonIdeas', {});
};

export const clickedCommonIdeasResults = () => {
	tutorialEmitter.emit('clickedCommonIdeasResults', {});
};

export const clickedSearch = (node: NCNode) => {
	tutorialEmitter.emit('clickedSearch', { node });
};

export const clickedLink = (node: NCNode) => {
	tutorialEmitter.emit('clickedLink', { node });
};

export const clickedMerge = (node: NCNode) => {
	tutorialEmitter.emit('clickedMerge', { node });
};

export const clickedConnect = (node: NCNode) => {
	tutorialEmitter.emit('clickedConnect', { node });
};

export const openedIdeaBox = () => {
	tutorialEmitter.emit('openedIdeaBox', {});
};

export const openedInvite = () => {
	tutorialEmitter.emit('openedInvite', {});
};

export const closedInvite = () => {
	tutorialEmitter.emit('closedInvite', {});
};

export const invitedCollaborator = () => {
	tutorialEmitter.emit('invitedCollaborator', {});
};

export const selectedNode = (node: NCNode) => {
	tutorialEmitter.emit('selectedNode', { node });
};

export const mergedNodes = (node: NCNode) => {
	tutorialEmitter.emit('mergedNodes', { node });
};

export const connectedNodes = (node: NCNode) => {
	tutorialEmitter.emit('connectedNodes', { node });
};

export const addedTwoNodes = (firstNode: NCNode, secondNode: NCNode) => {
	setTimeout(() => {
		tutorialEmitter.emit('addedTwoNodes', { firstNode, secondNode });
	}, 400);
};

export const addedSingleNode = (node: NCNode) => {
	setTimeout(() => {
		tutorialEmitter.emit('addedSingleNode', { node });
	}, 400);
};

export const addedCluster = (cluster: NCCluster, props: ClusterProps) => {
	setTimeout(() => {
		tutorialEmitter.emit('addedCluster', { cluster, props });
	}, 50);
};

export const likedNode = (node: NCNode) => {
	tutorialEmitter.emit('likedNode', { node });
};

export const likedResult = () => {
	tutorialEmitter.emit('likedResult', {});
};

export const likedCluster = (cluster: NCCluster) => {
	tutorialEmitter.emit('likedCluster', { cluster });
};

export const openedProjectVisionPanel = () => {
	tutorialEmitter.emit('openedProjectVisionPanel', {});
};

export const openedAddToBoardPanel = () => {
	tutorialEmitter.emit('openedAddToBoardPanel', {});
};

export const addedWordsFromPanel = (nodes: Array<NCNode>) => {
	tutorialEmitter.emit('addedWordsFromPanel', { nodes });
};

export const afterAutoSpark = (nodes: Array<NCNode>) => {
	tutorialEmitter.emit('afterAutoSpark', { nodes });
};

export const updatedLabel = (node: NCNode) => {
	tutorialEmitter.emit('updatedLabel', { node });
};

export const loadedNewAnalysis = (analysisKey: string) => {
	tutorialEmitter.emit('afterNewAnalysis', { analysisKey: analysisKey });
};

export const openedNotepad = () => {
	tutorialEmitter.emit('openedNotepad', {});
};

export const openedExport = () => {
	tutorialEmitter.emit('openedExport', {});
};

export const openedPresentationPreview = () => {
	tutorialEmitter.emit('openedPresentationPreview', {});
};

export const addedToNote = (node: NCNode) => {
	tutorialEmitter.emit('addedToNote', { node });
};

export const selectingItems = () => {
	tutorialEmitter.emit('selectingItems', {});
};

export const doneSelectingItems = () => {
	tutorialEmitter.emit('doneSelectingItems', {});
};

export const synthesiseResult = () => {
	tutorialEmitter.emit('synthesiseResult', {});
};

export const sparkResultsGenerated = (pairs: Array<[firstSuggestion: string, secondSuggestion: string]>) => {
	tutorialEmitter.emit('sparkResultsGenerated', { pairs });
}

export const empathiseResultsGenerated = (suggestions: Array<{ color: EmpathiseColour; values: string }>) => {
	tutorialEmitter.emit('empathiseResultsGenerated', { suggestions });
};

export const brainstormResultsGenerated = (results: Array<{ snippet: string, title: string }>) => {
	tutorialEmitter.emit(TUTORIAL_EVENT_TYPES.brainstormResultsGenerated, { results });
}

export const commonIdeasResultsGenerated = (results: Array<{ snippet: string, title: string }>) => {
	tutorialEmitter.emit(TUTORIAL_EVENT_TYPES.commonIdeasResultsGenerated, { results });
}
export const clickedGenerateMotivations = () => {
	tutorialEmitter.emit(TUTORIAL_EVENT_TYPES.clickedGenerateMotivations, {});
}

export function personaGenerated(results: { snippet: string; title: string; }[]) {
	tutorialEmitter.emit(TUTORIAL_EVENT_TYPES.personaResultsGenerated, {results})
}

export function motivationsGenerated(results: { snippet: string; title: string; }[]) {
	tutorialEmitter.emit(TUTORIAL_EVENT_TYPES.stakeholderMotivationsGenerated, {results})
}

export function clickedGeneratePresentation() {
	tutorialEmitter.emit(TUTORIAL_EVENT_TYPES.clickedGeneratePresentation, {});
}

export function lateralSliderMoved(value: number) {
	tutorialEmitter.emit(TUTORIAL_EVENT_TYPES.lateralSliderMoved, {value});
}


export function closedTool(id: string) {
	tutorialEmitter.emit(TUTORIAL_EVENT_TYPES.closedTool, {id});
}

export function disableChatbot() {
	tutorialEmitter.emit(TUTORIAL_EVENT_TYPES.disableChatbot, {});
}

export function enableChatbot() {
	tutorialEmitter.emit(TUTORIAL_EVENT_TYPES.enableChatbot, {});
}
export function aiHaikusGenerated(results: { snippet: string; title: string; }[]) {
	tutorialEmitter.emit(TUTORIAL_EVENT_TYPES.aiHaikuResultsGenerated, {results});
}

export function haikusGenerated(results: { snippet: string; title: string; }[]) {
	tutorialEmitter.emit(TUTORIAL_EVENT_TYPES.haikuResultsGenerated, {results});
}

export function imageInspireGenerated(results: { snippet: string; title: string; }[]) {
	tutorialEmitter.emit(TUTORIAL_EVENT_TYPES.imageInspireGenerated, {results});
}

export function videoInspireGenerated(results: { snippet: string; title: string; }[]) {
	tutorialEmitter.emit(TUTORIAL_EVENT_TYPES.imageInspireGenerated, {results});
}

export function imageSynthesiseGenerated(results: { snippet: string; title: string; }[]) {
	tutorialEmitter.emit(TUTORIAL_EVENT_TYPES.imageSynthesiseGenerated, {results});
}

export function taglinesGenerated(results: { snippet: string; title: string; }[]) {
	tutorialEmitter.emit(TUTORIAL_EVENT_TYPES.taglineResultsGenerated, {results});
}

export function audiencesGenerated(results: { snippet: string; title: string; }[]) {
	tutorialEmitter.emit(TUTORIAL_EVENT_TYPES.audiencesGenerated, {results});
}

export function searchResultsGenerated(results: Array<SearchEntry>) {
	tutorialEmitter.emit(TUTORIAL_EVENT_TYPES.searchResultsGenerated, {results});
}
