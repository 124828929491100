import { HEART_COLOURS, HEART_POSITIONS } from '../constants';
import {
	getLinkedNodes,
	hasMovedNoticably,
	hexOrNamedColour,
	removeEl,
	removeNoDeleteAnimation,
} from './graphUtils';
import * as d3 from 'd3';
import { GraphProps, MenuOption } from '../../types';
import { PieArcDatum } from 'd3';
import { AvailableColour, GraphMode, NCNode } from '../../../../src/commonTypes';
import { detectBrowser } from '../../../../src/commonMisc';

// Number of lines for the input field in the node
const getNumberLines = (label: string, nodeRadius: number, isRect: boolean): number => {
	if (isRect) {
		if (label.length > 260) {
			return 9;
		} else if (label.length > 220) {
			return 8;
		} else if (label.length > 180) {
			return 7;
		} else if (label.length > 150) {
			return 6;
		} else if (label.length > 120) {
			return 5;
		} else if (label.length > 80) {
			return 4;
		} else if (label.length > 40) {
			return 3;
		} else {
			return 2;
		}
	}

	if (nodeRadius > 50) {
		if (label.length > 50) {
			return 4;
		}
		if (label.length > 32) {
			return 3;
		}
		if (label.length > 12) {
			return 2;
		}
		return 1;
	} else {
		if (label.length > 32) {
			return 4;
		}
		if (label.length > 20) {
			return 3;
		}
		if (label.length > 10) {
			return 2;
		}
		return 1;
	}
};

const getInputHeight = (label: string, nodeRadius: number, isRect: boolean): number => {
	const numLines = getNumberLines(label, nodeRadius, isRect);
	switch (numLines) {
		case 9:
		case 8:
			return 130;
		case 7:
			return 113;
		case 6:
			return 100;
		case 5:
			return 90;
		case 4:
			return isRect ? 74 : 64;
		case 3:
			return isRect ? 62 : 53;
		case 2:
			return isRect ? 49 : 39;
		case 1:
		default:
			return 26;
	}
};

const getFontSize = (d: NCNode, nodeRadius: number): string => {
	if (d.style === 'rect') {
		if (d.label) {
			if (d.label.length > 600) {
				return '7px';
			} else if (d.label.length > 400) {
				return '8px';
			} else if (d.label.length > 300) {
				return '9px';
			} else if (d.label.length > 250) {
				return '10px';
			} else if (d.label.length > 220) {
				return '11px';
			} else if (d.label.length > 180) {
				return '12px';
			} else if (d.label.length > 150) {
				return '13px';
			} else if (d.label.length > 80) {
				return '14px';
			} else if (d.label.length > 40) {
				return '15px';
			}
		}
		return '16px';
	}
	return `${
		15 - getNumberLines(d.label || '', nodeRadius, d.style === 'rect') - (nodeRadius > 50 ? 0 : 1)
	}px`;
};

export const generateNodeId = () => `O-${Math.random().toString(16).slice(2)}`;

const nodeKeyFn = (d: NCNode) => d.uid;

// The group in which nodes (circles) will be placed
export const createNodes = (
	nodesElement: d3.Selection<SVGGElement, NCNode, SVGGElement, null>,
	nodes: Array<NCNode>,
	enterNode: (
		elem: d3.Selection<d3.EnterElement, NCNode, SVGGElement, null>
	) => d3.Selection<SVGGElement, NCNode, SVGGElement, null>,
	updateNode: (
		elem: d3.Selection<SVGGElement, NCNode, SVGGElement, null>
	) => d3.Selection<SVGGElement, NCNode, SVGGElement, null>,
	isDeleteAnimated: boolean
): d3.Selection<SVGGElement, NCNode, SVGGElement, null> =>
	nodesElement
		.data<NCNode>(nodes, nodeKeyFn)
		.join<SVGGElement>(
			enterNode,
			updateNode,
			isDeleteAnimated ? removeEl : removeNoDeleteAnimation
		);

const parseTransform = (transform: string | null): { x: number; y: number } => {
	if (!transform) {
		return { x: 0, y: 0 };
	}
	const translate = transform.match(/translate\(([^,]+),([^,]+)\)/);
	if (!translate) {
		return { x: 0, y: 0 };
	}
	return { x: parseFloat(translate[1]), y: parseFloat(translate[2]) };
};

// Only updates the position of the nodes
export const updateNodePositions = (
	node: d3.Selection<SVGGElement, NCNode, SVGGElement, null>
): void => {
	node.each(function (d) {
		const oldTranslate = this.getAttribute('transform');
		const oldPos = parseTransform(oldTranslate);
		if (hasMovedNoticably(oldPos, d)) {
			this.setAttribute('transform', `translate(${d.x}, ${d.y})`);
		}
	});
	// Disabled as this is inefficient
	// node.attr('transform', (d) => `translate(${d.x}, ${d.y})`);
};

export const updateNodes = (
	node: d3.Selection<SVGGElement, NCNode, SVGGElement, null>,
	graphProps: GraphProps
): void => {
	// Updates the position of the nodes (circle/rect) based on node data.
	updateNodePositions(node);

	// Applies 'hidden' class to nodes that are flagged as hidden.
	node.classed('hidden', (d) => Boolean(d.hidden));

	// Sets the stroke color for circle nodes based on whether node coloring is enabled.
	node
		.select('circle.node')
		.attr('stroke', graphProps.areNodesColoured ? (d) => hexOrNamedColour(d.colour) : '#91909A');

	// Sets the stroke color for rectangle nodes based on whether node coloring is enabled.
	node
		.select('rect.node')
		.attr('stroke', graphProps.areNodesColoured ? (d) => hexOrNamedColour(d.colour) : '#91909A');

	// Updates node shape from circle to rectangle for nodes that should be displayed as rectangles.
	const rectNode = node.filter((d) => d.style === 'rect');
	rectNode.select('circle.node').classed('hidden', true); // Hides the circle.
	rectNode.select('rect.node').classed('hidden', false); // Displays the rectangle.

	// Get the colors assigned to board users.
	const USER_HEART_COLOURS = graphProps.boardUsers.map((u) => u.colour);

	// Loops through the user colors to position the heart icons for each color.
	USER_HEART_COLOURS.forEach((colourClass, index) => {
		const colourProps = HEART_POSITIONS[index]; // Gets heart position coordinates based on index.

		// Selects the heart icon for the current color class.
		const hearts = node.select(`g > image.${colourClass}`);

		// Toggles the visibility of hearts based on whether the node has 'likes' for the color class.
		hearts.each(function (d: NCNode) {
			const el = this as unknown as HTMLElement;
			el.classList.toggle('hidden', !d.likes || !d.likes[colourClass]);
		});

		// Filters hearts that are not hidden and positions them based on node shape.
		const heartsNotHidden = hearts.filter((d) => (d.likes ? d.likes[colourClass] : false));
		heartsNotHidden
			.filter((d) => d.style === 'rect')
			.attr('x', index * 18 - 120) // Adjust x position for rectangular nodes.
			.attr('y', 60); // Sets y position for rectangular nodes.

		heartsNotHidden
			.filter((d) => d.style !== 'rect')
			.attr('x', graphProps.nodeRadius > 50 ? colourProps.x : colourProps.x / 1.6) // Adjust x position for circular nodes based on node radius.
			.attr('y', graphProps.nodeRadius > 50 ? colourProps.y : colourProps.y / 1.6); // Adjust y position for circular nodes.
	});

	// Filters out heart colors that are not used by the current board users.
	const NOT_USED_COLOURS = HEART_COLOURS.filter((colour) => !USER_HEART_COLOURS.includes(colour));
	const userCount = USER_HEART_COLOURS.length; // Gets the count of users.

	// Loops through unused colors and positions their hearts.
	NOT_USED_COLOURS.forEach((colourClass, index) => {
		const colourProps = HEART_POSITIONS.slice(USER_HEART_COLOURS.length)[index]; // Gets heart positions for unused colors.

		// Selects the heart icon for the current unused color class.
		const hearts = node.select(`g > image.${colourClass}`);

		// Toggles the visibility of hearts based on whether the node has 'likes' for the unused color class.
		hearts.each(function (d: NCNode) {
			const el = this as unknown as HTMLElement;
			el.classList.toggle('hidden', !d.likes || !d.likes[colourClass]);
		});

		// Filters hearts that are not hidden and positions them based on node shape and user count.
		const heartsNotHidden = hearts.filter((d) => (d.likes ? d.likes[colourClass] : false));
		heartsNotHidden
			.filter((d) => d.style === 'rect')
			.attr('x', (userCount + index > 14 ? index : userCount + index) * 18 - 120) // Adjust x position for rectangular nodes.
			.attr('y', userCount + index > 14 ? 44 : 60); // Adjust y position based on index and user count.

		heartsNotHidden
			.filter((d) => d.style !== 'rect')
			.attr('x', graphProps.nodeRadius > 50 ? colourProps.x : colourProps.x / 1.6) // Adjust x position for circular nodes based on node radius.
			.attr('y', graphProps.nodeRadius > 50 ? colourProps.y : colourProps.y / 1.6); // Adjust y position for circular nodes.
	});

	// Updates the text for the "like" action based on whether the current user has liked the node.
	node
		.select("[data-action='like'] tspan")
		.text((d) => (d.likes && d.likes[graphProps.userColourClass] ? 'Unlike' : 'Like'));

	// Updates the text for the "merge" action based on whether the node is merged.
	node.select("[data-action='merge'] tspan").text((d) => (d.merged ? 'Unmerge' : 'Merge'));
};

const appendLabelInput = (
	node: d3.Selection<SVGGElement, NCNode, SVGGElement, null>,
	nodeRadius: number,
	graphMode: GraphMode
): d3.Selection<HTMLTextAreaElement, NCNode, SVGGElement, null> => {
	const browser = detectBrowser();
	const textArea = node
		.append('foreignObject')
		.append<HTMLAnchorElement>('xhtml:a')
		.classed('node-link', true)
		.attr('target', '_blank')
		.attr('href', (d) => d.href || null)
		.append<HTMLTextAreaElement>('xhtml:textarea')
		.classed('input', true)
		.classed('overflow-hidden', browser === 'Safari')
		.classed('inversed', (d) => Boolean(d.nodeColour && graphMode !== 'ideate'))
		.attr('id', (d) => d.uid)
		.attr('type', 'text')
		.attr('autofocus', true)
		.attr('placeholder', 'Enter label');
	updateLabelInput(node, nodeRadius);
	updateInputSizesToFit(node, nodeRadius);
	return textArea;
};

// export const updateLabelInput = (
// 	node: d3.Selection<SVGGElement, NCNode, SVGGElement, null>,
// 	nodeRadius: number
// ): void => {
// 	node
// 		.select('g > foreignObject:not(.node-image):not(.node-video)')
// 		.attr('y', (d) => {
// 			const numLines = getNumberLines(d.label || '', nodeRadius, d.style === 'rect');
// 			if (d.style === 'rect') {
// 				if (numLines > 8) {
// 					return -8 * numLines - 5;
// 				}
// 				return -8 * numLines - 10;
// 			}
// 			return -8 * numLines - 6;
// 		})
// 		.attr('height', (d) => getInputHeight(d.label || '', nodeRadius, d.style === 'rect'))
// 		.select<HTMLTextAreaElement>('.input')
// 		.style(
// 			'min-height',
// 			(d) => `${getInputHeight(d.label || '', nodeRadius, d.style === 'rect')}px`
// 		)
// 		.attr('rows', (d) => getNumberLines(d.label || '', nodeRadius, d.style === 'rect'))
// 		.attr('title', (d) => (d.href ? 'Open link in new tab' : d.label || ''))
// 		.style('font-size', (d) => getFontSize(d, nodeRadius))
// 		.each(function (d) {
// 			this.innerText = d.label || '';
// 			this.value = d.label || '';
// 		});
// };

// Focus-preserving input update
export const updateLabelInput = (
	nodes: d3.Selection<SVGGElement, NCNode, SVGGElement, null>,
	nodeRadius: number
) => {
	nodes.each(function(d) {
		const node = d3.select(this);
		const input = node.select<HTMLTextAreaElement>('.input');
		const wasFocused = document.activeElement === input.node();
		const cursorPos = input.node()?.selectionStart;
		const scrollPos = input.node()?.scrollTop;

		// Update properties
		const numLines = getNumberLines(d.label || '', nodeRadius, d.style === 'rect');
		node.select('foreignObject')
			.attr('y', d.style === 'rect' 
				? (numLines > 8 ? -8 * numLines - 5 : -8 * numLines - 10)
				: -8 * numLines - 6)
			.attr('height', getInputHeight(d.label || '', nodeRadius, d.style === 'rect'));

		input
			.text(d.label || '')
			.attr('rows', numLines)
			.style('font-size', getFontSize(d, nodeRadius));

		// Restore focus and state
		if (wasFocused && input.node()) {
			requestAnimationFrame(() => {
				input.node()!.focus();
				if (cursorPos !== undefined) {
					input.node()!.setSelectionRange(cursorPos, cursorPos);
					input.node()!.scrollTop = scrollPos || 0;
				}
			});
		}
	});
};
const RECT_WIDTH = 4;
const RECT_HEIGHT = 2.5;
const getRectInputWidth = (node: NCNode, nodeRadius: number) => {
	return RECT_WIDTH * nodeRadius;
};

const updateInputSizesToFit = (
	nodes: d3.Selection<SVGGElement, NCNode, SVGGElement, null>,
	nodeRadius: number
): void => {
	// Alter size of input to maximise usage of space
	nodes
		.select('g.menu-open > foreignObject:not(.node-image):not(.node-video)')
		.attr('x', (d) =>
			d.style === 'rect' ? -(getRectInputWidth(d, nodeRadius) / 2) + 8 : -nodeRadius * 1.21 + 6
		)
		.attr('width', (d) =>
			d.style === 'rect' ? getRectInputWidth(d, nodeRadius) - 10 : 2.4 * nodeRadius - 10
		);
	nodes
		.select('g:not(.menu-open) > foreignObject:not(.node-image):not(.node-video)')
		.attr('x', (d) =>
			d.style === 'rect' ? -(getRectInputWidth(d, nodeRadius) / 2) + 8 : -nodeRadius + 8
		)
		.attr('width', (d) =>
			d.style === 'rect' ? getRectInputWidth(d, nodeRadius) - 16 : 2 * nodeRadius - 16
		);
};

const appendNodeImg = (node: d3.Selection<SVGGElement, NCNode, SVGGElement, null>) => {
	node
		.append('foreignObject')
		.attr('x', -81)
		.attr('y', -81)
		.attr('width', 162)
		.attr('height', 162)
		.attr('class', 'node-image')
		.append('xhtml:div')
		.attr('class', 'img-div')
		.attr('id', (d) => d.uid)
		.style('background-image', (d) => {
			if (d.src?.startsWith('data:image')) {
				return `url(${d.src})`;
			}
			if (d.src?.startsWith('http') && !d.src?.includes(window.location.host)) {
				return `url(/export-images?responseType=blob&url=${encodeURIComponent(d.src)})`;
			}
			console.log(d.src);
			return d.src ? `url(${d.src})` : null;
		});
};

const appendNodeVideo = (node: d3.Selection<SVGGElement, NCNode, SVGGElement, null>) => {
	const VIDEO_HIEGHT = 252;
	const VIDEO_WIDTH = 142;
	node
		.append('foreignObject')
		.attr('x', -VIDEO_HIEGHT / 2)
		.attr('y', -(VIDEO_WIDTH + 14) / 2)
		.attr('width', VIDEO_HIEGHT)
		.attr('height', VIDEO_WIDTH)
		.attr('class', 'node-video')
		.append('xhtml:div')
		.attr('class', 'video-div')
		.attr('id', (d) => d.uid)
		.append('xhtml:video')
		.attr('width', VIDEO_HIEGHT)
		.attr('height', VIDEO_WIDTH)
		.attr('controls', 'controls') // Add controls for play, pause, etc.
		// .attr('controlsList', 'noremoteplayback')
		.attr('src', (d) => {
			if (d.src?.startsWith('http') && !d.src?.includes(window.location.host)) {
				// Fetch video via proxy if it's an external URL
				return `/export-videos?responseType=blob&url=${encodeURIComponent(d.src)}`;
			}
			return d.src || null; // Return the source URL or null if unavailable
		})
		.attr('type', 'video/mp4') // Set the video type, adjust as needed
		.style('border-top-left-radius', '4px') // Apply border-radius to top-left
		.style('border-top-right-radius', '4px'); // Apply border-radius to top-right
};

const appendCircleOrRect = (
	node: d3.Selection<SVGGElement, NCNode, SVGGElement, null>,
	graphProps: GraphProps
): void => {
	node
		// .filter((d) => !d.style)
		.append('circle')
		.attr('class', (d) => (d.style !== 'rect' ? 'node' : 'node hidden'))
		.attr('r', graphProps.isDeleteAnimated && !graphProps.automation ? 0 : graphProps.nodeRadius)
		.attr('fill', '#fff')
		.attr('stroke', graphProps.userColourHex)
		.call((enter) => enter.transition().attr('r', graphProps.nodeRadius));

	node
		// .filter((d) => d.style === 'rect')
		.append('rect')
		.attr('class', (d) => (d.style === 'rect' ? 'node' : 'node hidden'))
		.attr('width', RECT_WIDTH * graphProps.nodeRadius)
		.attr('height', RECT_HEIGHT * graphProps.nodeRadius)
		.attr('rx', 5)
		.attr('ry', 5)
		.attr('x', -(RECT_WIDTH / 2) * graphProps.nodeRadius)
		.attr('y', -(RECT_HEIGHT / 2) * graphProps.nodeRadius)
		.attr('fill', '#fff')
		.attr('stroke', graphProps.userColourHex)
		.call((enter) => enter.transition().attr('r', graphProps.nodeRadius));
};

const appendDeleteButton = (
	nodes: d3.Selection<SVGGElement, NCNode, SVGGElement, null>,
	graphProps: GraphProps
): void => {
	// Remove from all nodes
	nodes.filter('g').selectAll('.delete').remove();
	// Add delete to node with open menu
	nodes
		.filter('g.menu-open')
		.filter((d) => graphProps.isEditable || d.style === 'rect')
		.append('image')
		.attr('class', 'delete')
		.attr('href', `/assets/graph/delete.svg`)
		.attr('x', (d) => (d.style === 'rect' ? graphProps.nodeRadius * 1.55 : -8))
		.attr('y', (d) => (d.style === 'rect' ? 54 : 36))
		.attr('width', 16)
		.attr('height', 16);
};

const appendMoveConnected = (nodes: d3.Selection<SVGGElement, NCNode, SVGGElement, null>): void => {
	// Open the menu which has the menu-open class
	nodes.selectAll<SVGGElement, NCNode>('.nodes > g.menu-open .menu').call((menuEl) => {
		const node = menuEl.node() && menuEl.datum();
		if (node && getLinkedNodes(node).length > 0) {
			menuEl
				.selectAll<SVGGElement, NCNode>('.move-connected')
				.data(menuEl.data())
				.enter()
				.append('g')
				.attr('fill', `#333`)
				.attr('class', 'move-connected')
				.attr('transform', 'translate(130, -140)')
				.attr('title', 'Drag to move this and connected nodes')
				.attr('width', 130)
				.attr('height', 28)
				.call((moveGroupEl) => {
					moveGroupEl
						.append('rect')
						.attr('fill', '#eee')
						.attr('width', 180)
						.attr('height', 28)
						.attr('rx', 5)
						.attr('ry', 5);
					moveGroupEl
						.append('image')
						.attr('href', '/assets/graph/move.svg')
						.attr('width', 16)
						.attr('height', 16)
						.attr('transform', 'translate(9, 6)');
					moveGroupEl
						.append('text')
						.text('Move connected nodes')
						.attr('transform', 'translate(36, 18)');
				});
		}
	});
};

const radialToolbarPie = d3.pie<MenuOption>().value(1);
const appendToolbarButtons = (
	nodes: d3.Selection<SVGGElement, NCNode, SVGGElement, null>,
	graphProps: GraphProps
): void => {
	// Radial toolbar for circle nodes
	nodes
		.filter((d) => d.style !== 'rect')
		.selectAll<SVGGElement, NCNode>('g.menu-open .menu')
		.call((menuEl) => {
			const filteredMenuOptions = graphProps.menuOptions.filter((d) => d.shortText);

			const arcs = menuEl
				.selectAll<SVGGElement, PieArcDatum<MenuOption>>('.arc')
				.data(radialToolbarPie(filteredMenuOptions))
				.enter()
				.append('g')
				.attr('fill', `var(--${graphProps.userColourClass}-menu)`)
				.attr('data-action', (d) => d.data.label.toLowerCase());

			const { likes, merged } = menuEl.node()
				? menuEl.datum()
				: { likes: undefined, merged: undefined };

			const radialToolbarArc = d3
				.arc<PieArcDatum<MenuOption>>()
				.innerRadius(graphProps.nodeRadius * 1.25)
				.outerRadius(graphProps.nodeRadius * 2.5)
				.padAngle(0.02);

			arcs.append('path').attr('class', 'menu-button').attr('d', radialToolbarArc);
			arcs
				.append('image')
				.attr('href', (d) => `/assets/graph/${d.data.label.toLowerCase()}.svg`)
				.attr('transform', (d) => {
					const coords = radialToolbarArc.centroid(d);
					coords[0] = coords[0] - 6; // x
					coords[1] = coords[1] - 20; // y
					return `translate(${coords[0]}, ${coords[1]})`;
				})
				.attr('width', 16)
				.attr('height', 16);

			arcs
				.append('text')
				.attr('transform', (d) => {
					const coords = radialToolbarArc.centroid(d);
					coords[0] = coords[0] + 2; // x
					coords[1] = coords[1] + 16; // y
					return `translate(${coords[0]}, ${coords[1]})`;
				})
				.append('tspan')
				.text((d) => {
					if (d.data.label === 'Like' && likes && likes[graphProps.userColourClass]) {
						return 'Unlike';
					} else if (d.data.label === 'Merge' && merged) {
						return 'Unmerge';
					}
					return d.data.label;
				});

			return menuEl;
		});

	// Rectangular toolbar for rect nodes
	nodes
		.filter((d) => d.style === 'rect')
		.selectAll<SVGGElement, NCNode>('g.menu-open .menu')
		.call((menuEl) => {
			const filteredMenuOptions = graphProps.menuOptions.filter((d) => d.longText);

			const buttons = menuEl
				.selectAll<SVGGElement, MenuOption>('.arc')
				.data(filteredMenuOptions)
				.enter()
				.append('g')
				.attr('transform', (d, i) => {
					const row = Math.floor(i / 2);
					const column = i % 2;
					return `translate(${(column - 1) * 126 + 2}, ${row === 0 ? -123 : row === 1 ? 83 : 124})`;
				})
				.attr('data-action', (d) => d.label.toLowerCase());

			const { likes, merged } = menuEl.node()
				? menuEl.datum()
				: { likes: undefined, merged: undefined };

			buttons
				.append('rect')
				.attr('class', 'menu-rect')
				.attr('fill', `var(--${graphProps.userColourClass}-menu)`)
				.attr('rx', 5)
				.attr('ry', 5)
				.attr('width', 124)
				.attr('height', 40);
			buttons
				.append('image')
				.attr('href', (d) => `/assets/graph/${d.label.toLowerCase()}.svg`)
				.attr('transform', `translate(12, 12)`)
				.attr('width', 16)
				.attr('height', 16);

			buttons
				.append('text')
				.attr('transform', `translate(66, 25)`)
				.append('tspan')
				.text((d) => {
					if (d.label === 'Like' && likes && likes[graphProps.userColourClass]) {
						return 'Unlike';
					} else if (d.label === 'Merge' && merged) {
						return 'Unmerge';
					}
					return d.label;
				});

			return menuEl;
		});
};

const appendHeart = (
	node: d3.Selection<SVGGElement, NCNode, SVGGElement, null>,
	colourClass: AvailableColour
) => {
	node
		.append('image')
		.attr('href', `/assets/graph/heart-${colourClass}.svg`)
		.attr('width', 16)
		.attr('height', 16)
		.attr('class', (d) =>
			d.likes && d.likes[colourClass] ? `heart ${colourClass}` : `heart hidden ${colourClass}`
		);
};

const appendHearts = (node: d3.Selection<SVGGElement, NCNode, SVGGElement, null>): void => {
	HEART_COLOURS.forEach((colourClass) => {
		appendHeart(node, colourClass as AvailableColour);
	});
};

const isPieDatum = (
	menu: PieArcDatum<MenuOption> | MenuOption
): menu is PieArcDatum<MenuOption> => {
	return (menu as PieArcDatum<MenuOption>).data !== undefined;
};

const disableOrEnableTools = (
	nodes: d3.Selection<SVGGElement, NCNode, SVGGElement, null>,
	aiStatus: boolean
): void => {
	nodes
		.selectAll<
			SVGGElement,
			PieArcDatum<MenuOption> | MenuOption
		>('.nodes > g.menu-open .menu g:not(.move-connected)')
		.attr('class', (d) => {
			const menuData = isPieDatum(d) ? d.data : d;
			if (!menuData.enabled || (menuData.requiresAi && !aiStatus)) {
				return 'arc disabled';
			}
			return 'arc';
		});
};

const closeMenusForInactiveNodes = (
	nodes: d3.Selection<SVGGElement, NCNode, SVGGElement, null>
): void => {
	nodes.selectAll('.nodes > g:not(.menu-open) .menu').call((menuEl) => {
		menuEl.selectAll('.arc, .move-connected').remove();
		return menuEl;
	});
};

export const updateNodeAndMenu = (
	nodes: d3.Selection<SVGGElement, NCNode, SVGGElement, null>,
	graphProps: GraphProps
): void => {
	updateNodeCircles(nodes, graphProps);
	updateInputSizesToFit(nodes, graphProps.nodeRadius);
	appendToolbarButtons(nodes, graphProps);
	disableOrEnableTools(nodes, graphProps.aiStatus);
	closeMenusForInactiveNodes(nodes);
	if (graphProps.graphMode !== 'ideate') {
		appendMoveConnected(nodes);
		appendDeleteButton(nodes, graphProps);
	}
};

/**
 * Function to use with .join to create a new node
 */
// export const enterNode = (
// 	elem: d3.Selection<d3.EnterElement, NCNode, SVGGElement, null>,
// 	graphProps: GraphProps,
// 	updateLabel: (event: InputEvent, finishedEditing?: boolean) => void,
// 	dragBehavior: d3.DragBehavior<SVGGElement, NCNode, unknown>
// ): d3.Selection<SVGGElement, NCNode, SVGGElement, null> => {
// 	const node = elem
// 		.append('g')
// 		.attr('id', (d) => `node-${d.uid}`)
// 		.classed('menu-open', false);

// 	node
// 		.on('mouseover', function () {
// 			this.classList.add('hover');
// 		})
// 		.on('mouseout', function () {
// 			this.classList.remove('hover');
// 		});

// 	appendCircleOrRect(node, graphProps);

// 	// Text input for node label
// 	const input = appendLabelInput(
// 		node.filter((d) => !d.src),
// 		graphProps.nodeRadius,
// 		graphProps.graphMode
// 	);
// 	if (graphProps.isEditable) {
// 		input.attr('readonly', (d) => (d.colour !== graphProps.userColourClass ? true : null));
// 		input.on('input', (event: InputEvent) => updateLabel(event));
// 		input.on('change', (event: InputEvent) => {
// 			updateLabel(event, true);
// 		});
// 		setTimeout(() => {
// 			const inputNode = input.node();
// 			if (inputNode && !inputNode.value) {
// 				inputNode.focus();
// 			}
// 		});
// 	} else {
// 		input.attr('readonly', true);
// 	}

// 	appendNodeImg(node.filter((d) => !!d.src && !d.isVideo));
// 	appendNodeVideo(node.filter((d) => Boolean(d.src) && Boolean(d.isVideo)));

// 	// Menu placeholder
// 	node.append('g').attr('class', 'menu');

// 	if (dragBehavior) {
// 		// Allow nodes to be dragged
// 		node.call(dragBehavior);
// 	}

// 	appendHearts(node);

// 	updateNodes(node, graphProps);

// 	return node;
// };

export const enterNode = (
	elem: d3.Selection<d3.EnterElement, NCNode, SVGGElement, null>,
	graphProps: GraphProps,
	updateLabel: (event: InputEvent, finishedEditing?: boolean) => void,
	dragBehavior: d3.DragBehavior<SVGGElement, NCNode, unknown>
): d3.Selection<SVGGElement, NCNode, SVGGElement, null> => {
	const node = elem
		.append('g')
		.attr('id', (d) => `node-${d.uid}`)
		.classed('menu-open', false);

	node
		.on('mouseover', function () {
			this.classList.add('hover');
		})
		.on('mouseout', function () {
			this.classList.remove('hover');
		});

	appendCircleOrRect(node, graphProps);

	// Text input for node label
	const input = appendLabelInput(
		node.filter((d) => !d.src),
		graphProps.nodeRadius,
		graphProps.graphMode
	);
	console.log('[on graphProps.userColourClass]', graphProps.userColourClass);

	if (graphProps.isEditable) {
		input.attr('readonly', (d) => (d.colour !== graphProps.userColourClass ? true : null));
		input.on('input', (event: InputEvent) => updateLabel(event));
		input.on('change', (event: InputEvent) => {
			console.log('[on input.on(change)');
			updateLabel(event, true);
		});
		setTimeout(() => {
			const inputNode = input.node();
			console.log('[on inputNode]', inputNode, inputNode?.value);
			
			if (inputNode && !inputNode.value) {
				// inputNode.focus();
				input.each(function(d) {
					// Focus the input if its colour equals the user colour class.
					if (d.colour === graphProps.userColourClass) {
						this.focus();
					}
				});
			}
		});
	} else {
		input.attr('readonly', true);
	}

	appendNodeImg(node.filter((d) => !!d.src && !d.isVideo));
	appendNodeVideo(node.filter((d) => Boolean(d.src) && Boolean(d.isVideo)));

	// Menu placeholder
	node.append('g').attr('class', 'menu');

	if (dragBehavior) {
		// Allow nodes to be dragged
		node.call(dragBehavior);
	}

	appendHearts(node);

	updateNodes(node, graphProps);

	return node;
};
const updateNodeCircles = (
	nodes: d3.Selection<SVGGElement, NCNode, SVGGElement, null>,
	graphProps: GraphProps
): void => {
	// Update radius to grow (or shrink) + fill colour
	nodes.select('circle').call((circleEl) =>
		circleEl
			.transition()
			.attr('r', (d) => {
				return graphProps.currentUser.activeNode === d.uid
					? graphProps.nodeRadius * 1.22
					: graphProps.nodeRadius;
			})
			.attr('fill', (d) => {
				// Find user for whom this node is active (if more than one, just use the first)
				const activeUser = graphProps.boardUsers.find((u) => u.activeNode === d.uid);
				if (activeUser) {
					return hexOrNamedColour(activeUser.colour);
				}
				if (d.nodeColour) {
					return hexOrNamedColour(d.nodeColour);
				}
				return '#fff';
			})
	);

	nodes.select('rect.node').call((rectEl) =>
		rectEl.transition().attr('fill', (d) => {
			// Find user for whom this node is active (if more than one, just use the first)
			const activeUser = graphProps.boardUsers.find((u) => u.activeNode === d.uid);
			if (activeUser) {
				return hexOrNamedColour(activeUser.colour);
			}
			if (d.nodeColour) {
				return hexOrNamedColour(d.nodeColour);
			}
			return '#fff';
		})
	);

	nodes
		.select('.nodes > g:not(.menu-open) > foreignObject:not(.node-image):not(.node-video)')
		.classed('inversed', (d) => Boolean(d.nodeColour && graphProps.graphMode !== 'ideate'));

	// Set active node only to have menu-open class
	nodes.classed('menu-open', (d) => {
		return graphProps.currentUser.activeNode === d.uid;
	});
	nodes.classed('hidden', (d) => d.hidden || false);

	nodes.classed('dotted', (d) => d.dotted || false);

	// Sort to ensure menu overlays other nodes
	nodes.sort((a, b) => {
		const activeA = graphProps.currentUser.activeNode === a.uid;
		const activeB = graphProps.currentUser.activeNode === b.uid;
		if (activeA) {
			return 1;
		} else if (activeB) {
			return -1;
		}
		return 0;
	});
};
