import { v4 as uuidv4 } from 'uuid';
import {
	GenerateTask,
	GraphMode,
	Motivations,
	NCNode,
	UserPersona,
} from '../../../../src/commonTypes';
import {
	getHeartIcon,
	showCommonIdeasNodesPrompt,
	updateSelectedCommonIdeasNodes,
} from './toolsUI';
import * as ncApi from '../apiRequests';
import { finishLoading, removeChildren, showAlert, startLoading } from './uiUtils';
import { IdeateGraph, InspireGraph } from '../graphs';
import { ANALYSES_TYPES } from '../../../../src/commonConstants';
import * as tutorialEvents from '../tutorials/tutorialEvents';
import {
	getBoardImages,
	like,
	motivationsStakeholderImage,
	userPersonaImage,
} from '../apiRequests';
import { levenshteinDistance, userPersonaToString } from '../../../../src/commonMisc';
import tippy, { followCursor, hideAll, roundArrow } from 'tippy.js';

const PER_MODE_TASKS: Record<GraphMode, Array<GenerateTask>> = {
	inspire: ['image-inspire'],
	ideate: ['persona', 'motivations', 'audience'],
	moodboard: ['image-synthesise'],
};

function getIdeateTaskString(task: GenerateTask, nodes: Array<NCNode>, inputString: string) {
	if (PER_MODE_TASKS['ideate'].includes(task)) {
		const node = nodes[0];
		if (node.parent) {
			// This node is part of a cluster so either the whole cluster or just this node depending on user selection
			const clusterCenterNode = node.parent;
			const ideateOptions = document.querySelector('.ideate-options')!;
			ideateOptions.classList.remove('hidden');
			const ideateOptionsCheckbox = ideateOptions.querySelector('input')!;
			ideateOptionsCheckbox.onchange = () => {
				fetchGenerateResults(nodes, task);
			};
			if (ideateOptionsCheckbox.checked) {
				// Include whole cluster
				const clusterNodes = globalThis.neuroCreate.graph!.nodes.filter(
					(n) => n.parent && n.parent.uid === clusterCenterNode.uid
				);
				return `${node.label || ''} ${clusterNodes.map((n) => n.label).join(' ')}`;
			} else {
				// Include cluster center node along with current node
				return `${node.label || ''} ${clusterCenterNode.label}`;
			}
		} else {
			// This node is the center of a cluster, so include whole cluster
			const clusterNodes = globalThis.neuroCreate.graph!.nodes.filter(
				(n) => n.parent && n.parent.uid === node.uid
			);
			return `${node.label || ''} ${clusterNodes.map((n) => n.label).join(' ')}`;
		}
	}
	// Not ideate task, so process input normally
	return inputString;
}

export const createStakeholderDiv = (stakeholder: Motivations['stakeholders'][0]) => {
	const stakeholderDiv = document.createElement('div');
	stakeholderDiv.className = 'stakeholder';
	const name = document.createElement('p');
	name.className = 'name';
	name.innerText = stakeholder.name;
	stakeholderDiv.append(name);
	const description = document.createElement('p');
	description.className = 'description';
	description.innerText = stakeholder.description;
	stakeholderDiv.append(description);
	if (stakeholder.imageUrl) {
		const img = document.createElement('img');
		img.src = stakeholder.imageUrl;
		img.width = 140;
		stakeholderDiv.prepend(img);
	}
	return stakeholderDiv;
};

const showGenerateError = () => {
	const container = document.querySelector('#generate-container')!;
	const loadingIndicator = container.querySelector('.loading')!;
	loadingIndicator.classList.add('hidden');
	showAlert(
		'Unable to generate results. Please try again later or contact us if this problem continues.'
	);
};

export const showGenerateResults = (
	generateTask: GenerateTask,
	entries: Array<{ snippet: string; title: string }>,
	isPreviousResults = false,
	nodes?: Array<NCNode>
) => {
	const container = document.querySelector('#generate-container')!;
	const loadingIndicator = container.querySelector('.loading')!;
	const resultsList = container.querySelector('.results-list')!;

	loadingIndicator.classList.add('hidden');

	if (entries.length === 0 && !isPreviousResults) {
		resultsList.innerHTML = '<p><strong>No results</strong></p>';
	} else {
		if (isPreviousResults) {
			const small = document.createElement('small');
			small.innerText = `Earlier generated ${generateTask.startsWith('image') ? 'images' : 'videos'}: `;
			resultsList.append(small);
			console.log(entries);
		}
		for (const entry of entries) {
			let getTextForIdeabox = (): string | null => {
				if (generateTask === 'audience') {
					return itemContent.innerText;
				} else if (generateTask.startsWith('image')) {
					return null;
				} else if (generateTask.startsWith('video')) {
					return null;
				} else {
					return entry.snippet;
				}
			};

			const listItem = document.createElement('li');
			const itemContent = document.createElement('div');
			itemContent.className = 'text';
			if (generateTask === 'audience') {
				itemContent.innerHTML = entry.snippet;
			} else if (generateTask.startsWith('image')) {
				itemContent.innerHTML = `<img src=${entry.snippet} alt='' width=200 />`;
			} else if (generateTask.startsWith('video')) {
				itemContent.innerHTML = `
				<video width="250" controls>
				  <source src="${entry.snippet}" type="video/mp4">
				  Your browser does not support the video tag.
				</video>
			  `;
			} else if (generateTask === 'persona') {
				const persona = JSON.parse(entry.snippet) as UserPersona;
				itemContent.innerText = userPersonaToString(persona);
				getTextForIdeabox = () => JSON.stringify(persona);
				if (persona.imageUrl) {
					const personaImg = document.createElement('img');
					personaImg.src = persona.imageUrl;
					personaImg.width = 200;
					itemContent.append(personaImg);
				} else {
					const generateImageButton = document.createElement('image-generator-with-quota');
					generateImageButton.onClick = () => {
						const boardId = document.body.dataset.boardId!;
						userPersonaImage(boardId, persona)
							.then((imageResult) => {
								const img = document.createElement('img');
								img.src = imageResult.snippet;
								img.width = 200;
								generateImageButton.remove();
								itemContent.prepend(img);
								persona.imageUrl = imageResult.snippet;
								like(boardId, 'persona', JSON.stringify(persona), null, false, imageResult.title);
							})
							.catch(() => {
								showAlert(
									'Unable to generate image. Please try again later or contact us if this problem continues.'
								);
							});
					};
					itemContent.prepend(generateImageButton);
				}
			} else if (generateTask === 'motivations') {
				const motivations = JSON.parse(entry.snippet) as Motivations;
				getTextForIdeabox = () => JSON.stringify(motivations);
				const title = entry.title;

				const hasImages = motivations.stakeholders.some((stakeholder) => stakeholder.imageUrl);
				if (!hasImages) {
					const generateImageButton = document.createElement('image-generator-with-quota');
					generateImageButton.align = 'right';
					generateImageButton.isQuotaOnRight = true;
					generateImageButton.cost = motivations.stakeholders.length;
					generateImageButton.onClick = () => {
						const boardId = document.body.dataset.boardId!;
						startLoading('Generating motivations images...');

						// Generate image for each stakeholder
						const promises = motivations.stakeholders.map((stakeholder, index) => {
							return motivationsStakeholderImage(
								boardId,
								stakeholder.name,
								stakeholder.description,
								title
							).then((imageResult) => {
								const img = document.createElement('img');
								img.src = imageResult.imageUrl;
								img.width = 140;
								const stakeholderDiv = itemContent.querySelector<HTMLDivElement>(
									`.stakeholder:nth-child(${index + 2})`
								)!;
								stakeholderDiv.prepend(img);
								stakeholder.imageUrl = imageResult.imageUrl;
							});
						});

						Promise.all(promises)
							.then(() => {
								generateImageButton.remove();
								finishLoading();
								like(boardId, 'motivations', JSON.stringify(motivations), null, false, title);
							})
							.catch(() => {
								finishLoading();
								showAlert(
									'Unable to generate images. Please try again later or contact us if this problem continues.'
								);
							});
					};
					itemContent.prepend(generateImageButton);
				}

				for (const stakeholder of motivations.stakeholders) {
					itemContent.append(createStakeholderDiv(stakeholder));
				}
			} else {
				itemContent.innerText = entry.snippet;
			}
			listItem.append(itemContent);
			const label = document.createElement('div');
			label.className = 'label';
			label.innerText = entry.title;
			listItem.append(label);

			listItem.append(
				getHeartIcon(
					generateTask,
					getTextForIdeabox,
					generateTask.startsWith('image') || generateTask.startsWith('video')
						? entry.snippet
						: undefined,
					entry.title
				)
			);

			if (isPreviousResults) {
				resultsList.append(listItem);
			} else {
				resultsList.prepend(listItem);
			}

			const graph = globalThis.neuroCreate.graph;
			if (graph) {
				const graphMode = graph.graphMode;
				if (graphMode === 'inspire') {
					listItem.classList.add('generate-list-item');
					if (generateTask.startsWith('image')) {
						listItem.classList.add('image-result');
					}

					if (nodes && nodes.length > 0) {
						listItem.addEventListener('click', (e) => {
							const { nodeName, parentElement } = e.target as HTMLElement;
							const isHeartIconClicked =
								nodeName === 'IMG' && parentElement?.classList.contains('heart');
							if (!isHeartIconClicked) {
								if (generateTask.startsWith('image')) {
									// Add image to board
									createSingleNodeFromGenerate(nodes[0], '', entry.snippet);
								} else if (generateTask.startsWith('video')) {
									// Add video to board
									createSingleNodeFromGenerate(nodes[0], '', entry.snippet, true);
								} else {
									if (generateTask === 'haiku') {
										// replace line breaks with comma and whitespace
										itemContent.innerText = itemContent.innerText.replace(/\n/g, ', ');
									}
									createSingleNodeFromGenerate(nodes[0], itemContent.innerText);
								}
							}
						});
						const tooltip = tippy(listItem, {
							theme: 'neuro',
							arrow: roundArrow,
							delay: 0,
							duration: 0,
							offset: [0, 30],
							placement: 'bottom',
							followCursor: true,
							content: 'Add to board',
							plugins: [followCursor],
						});
						listItem?.addEventListener('mouseover', (e) => {
							const { nodeName, parentElement } = e.target as HTMLElement;
							const isHeartIconHovered =
								nodeName === 'IMG' && parentElement?.classList.contains('heart');
							if (!isHeartIconHovered) {
								tooltip.show();
							} else {
								hideAll();
							}
							return;
						});
					}
				}
			}
		}
	}

	if (generateTask === 'haiku') {
		tutorialEvents.clickedHaikus();
	} else if (generateTask === 'persona') {
		tutorialEvents.clickedGenerateUserPersona();
	} else if (generateTask === 'motivations') {
		tutorialEvents.clickedGenerateMotivations();
	}
};

let currentRequestId: string | undefined;
export const fetchGenerateResults = (
	nodes: Array<NCNode>,
	task: GenerateTask,
	reset = true
): Promise<void> => {
	if (reset) {
		resetGenerate(task, fetchGenerateResults.bind(null, nodes), nodes[0]);
	}

	let inputString = nodes.map((node) => node.label).join(' ');

	inputString = getIdeateTaskString(task, nodes, inputString);

	const requestId = uuidv4();
	currentRequestId = requestId;

	return new Promise<void>((resolve) => {
		if (inputString) {
			ncApi
				.generate(document.body.dataset.boardId!, task, inputString)
				.then((entries) => {
					if (requestId === currentRequestId) {
						showGenerateResults(task, entries, false, nodes);
					}

					if (task === 'brainstorm') {
						tutorialEvents.brainstormResultsGenerated(entries);
					} else if (task === 'common') {
						tutorialEvents.commonIdeasResultsGenerated(entries);
					} else if (task === 'persona') {
						tutorialEvents.personaGenerated(entries);
					} else if (task === 'motivations') {
						tutorialEvents.motivationsGenerated(entries);
					} else if (task === 'ai-haiku') {
						tutorialEvents.aiHaikusGenerated(entries);
					} else if (task === 'haiku') {
						tutorialEvents.haikusGenerated(entries);
					} else if (task === 'image-inspire') {
						tutorialEvents.imageInspireGenerated(entries);
					} else if (task === 'video-inspire') {
						tutorialEvents.videoInspireGenerated(entries);
					} else if (task === 'image-synthesise') {
						tutorialEvents.imageSynthesiseGenerated(entries);
					} else if (task === 'proverb') {
						tutorialEvents.taglinesGenerated(entries);
					} else if (task === 'audience') {
						tutorialEvents.audiencesGenerated(entries);
					}
					resolve();
				})
				.catch(() => {
					showGenerateError();
					resolve();
				});
		} else {
			resolve();
		}
	});
};

export const showImageTab = (task: GenerateTask, node: NCNode) => {
	const graph = globalThis.neuroCreate.graph;
	if (graph) {
		resetGenerate('image-inspire', fetchGenerateResults.bind(null, [node]), node);

		const container = document.querySelector('#generate-container')!;
		const loadingIndicator = container.querySelector('.loading')!;
		loadingIndicator.classList.add('hidden');
		removeChildren(document.querySelector('#generate-container .results-list')!);
		const optionsContainer = document.querySelector(
			task === 'image-inspire' ? '.image-inspire-options' : '.image-synthesise-options'
		)!;
		optionsContainer.classList.remove('hidden');

		const generateImageButton = document.createElement('image-generator-with-quota');
		generateImageButton.align = 'left';
		generateImageButton.isQuotaOnRight = true;
		optionsContainer?.append(generateImageButton);
		generateImageButton.onclick = async () => {
			loadingIndicator.classList.remove('hidden');
			await fetchGenerateResults([node], task, false);
			tutorialEvents.clickedGenerateImageResult();
		};
		tutorialEvents.clickedGenerateImage();
	}
};

export const showVideoTab = (task: GenerateTask, node: NCNode) => {
	const graph = globalThis.neuroCreate.graph;
	if (graph) {
		resetGenerate('video-inspire', fetchGenerateResults.bind(null, [node]), node);

		const container = document.querySelector('#generate-container')!;
		const loadingIndicator = container.querySelector('.loading')!;
		loadingIndicator.classList.add('hidden');
		removeChildren(document.querySelector('#generate-container .results-list')!);
		const optionsContainer = document.querySelector(
			task === 'video-inspire' ? '.video-inspire-options' : '.video-synthesise-options'
		)!;
		optionsContainer.classList.remove('hidden');

		const generateVideoButton = document.createElement('video-generator-with-quota');
		generateVideoButton.align = 'left';
		generateVideoButton.isQuotaOnRight = true;
		optionsContainer?.append(generateVideoButton);
		generateVideoButton.onclick = async () => {
			loadingIndicator.classList.remove('hidden');
			await fetchGenerateResults([node], task, false);
			tutorialEvents.clickedGenerateVideoResult();
		};
		tutorialEvents.clickedGenerateVideo();
	}
};

const stopAddingNodesToCommonIdeas = () => {
	const graph = globalThis.neuroCreate.graph;
	if (graph) {
		graph.stopSelectingNodes('common-ideas');
	}
};

const clearGenerateResults = () => {
	const container = document.querySelector('#generate-container')!;
	const loadingIndicator = container.querySelector('.loading')!;
	loadingIndicator.classList.add('hidden');

	const resultsList = container.querySelector('.results-list')!;
	removeChildren(resultsList);
};

const getInitialNodeSelection = (node: NCNode) => {
	const initialNodes = globalThis.neuroCreate.graph!.selections.commonIdeasNodes || [node];

	if (initialNodes.length > 0) {
		updateSelectedCommonIdeasNodes(initialNodes);
	}

	return initialNodes;
};

const setupSelectNodesButton = (container: HTMLDivElement) => {
	const graph = globalThis.neuroCreate.graph!;
	const getSelectedNodes = () =>
		[...container.querySelectorAll<HTMLUListElement>('ul.selected-nodes li')]
			.map((li) => graph.getNode(li.dataset.nodeId || ''))
			.filter((node): node is NCNode => !!node);

	const select = container.querySelector<HTMLButtonElement>('button.select')!;
	select.onclick = (event) => {
		event.preventDefault();
		tutorialEvents.selectingItems();

		const selectedNodes = getSelectedNodes();
		graph.startSelectingNodes('commonIdeasNodes', selectedNodes, 'common-ideas');
		showCommonIdeasNodesPrompt(selectedNodes);
	};

	document
		.querySelector('#generate-container')!
		.querySelector('.close-panel')!
		.addEventListener('click', stopAddingNodesToCommonIdeas);

	return getSelectedNodes;
};

export const showBrainstormTab = (node: NCNode) => {
	const graph = globalThis.neuroCreate.graph;
	if (graph) {
		resetGenerate('brainstorm', fetchGenerateResults.bind(null, [node]), node);

		clearGenerateResults();

		const brainstormContainer = document.querySelector<HTMLDivElement>('.brainstorm-input')!;
		brainstormContainer.classList.remove('hidden');

		const initialNodes = getInitialNodeSelection(node);

		const getSelectedNodes = setupSelectNodesButton(brainstormContainer);

		const submit = brainstormContainer.querySelector<HTMLButtonElement>('button.submit')!;
		submit.disabled = initialNodes.length < 1;
		submit.onclick = async () => {
			document.querySelector('#generate-container .loading')!.classList.remove('hidden');

			const selectedNodes = getSelectedNodes();
			await fetchGenerateResults(selectedNodes, 'brainstorm');
			tutorialEvents.clickedBrainstormResults(selectedNodes);
		};

		tutorialEvents.clickedBrainstorm();
	}
};

export const showCommonIdeasTab = (node: NCNode) => {
	const graph = globalThis.neuroCreate.graph;
	if (graph) {
		resetGenerate('common', fetchGenerateResults.bind(null, [node]), node);

		clearGenerateResults();

		const commonIdeasContainer = document.querySelector<HTMLDivElement>('.common-ideas-input')!;
		commonIdeasContainer.classList.remove('hidden');

		const initialNodes = getInitialNodeSelection(node);

		const getSelectedNodes = setupSelectNodesButton(commonIdeasContainer);

		const submit = commonIdeasContainer.querySelector<HTMLButtonElement>('button.submit')!;
		submit.disabled = initialNodes.length < 2;
		submit.onclick = async () => {
			document.querySelector('#generate-container .loading')!.classList.remove('hidden');

			await fetchGenerateResults(getSelectedNodes(), 'common');
			tutorialEvents.clickedCommonIdeasResults();
		};

		tutorialEvents.clickedCommonIdeas();
	}
};

const countSlashes = (str: string) => {
	const re = /\//g;
	return ((str || '').match(re) || []).length;
};

export const resetGenerate = (
	generateTask: GenerateTask,
	fetchGenerateResults: (generateTask: GenerateTask) => Promise<void>,
	node: NCNode
) => {
	const graph = globalThis.neuroCreate.graph;
	const container = document.querySelector('#generate-container')!;
	document
		.querySelectorAll<HTMLButtonElement>('#generate-container .select-option button')
		.forEach((button) => {
			const currentButtonTask = button.dataset.task as GenerateTask;
			if (currentButtonTask === generateTask) {
				button.classList.add('selected');
			} else {
				button.classList.remove('selected');
			}

			if (PER_MODE_TASKS['ideate'].includes(currentButtonTask)) {
				const ideateGraph = graph?.graphMode === 'ideate' && (graph as IdeateGraph);
				if (
					ideateGraph &&
					ideateGraph?.activeAnalysis &&
					ANALYSES_TYPES[ideateGraph.activeAnalysis].generate?.includes(currentButtonTask)
				) {
					button.classList.remove('hidden');
				} else {
					button.classList.add('hidden');
				}
			} else if (PER_MODE_TASKS['inspire'].includes(currentButtonTask)) {
				if (graph?.graphMode === 'inspire') {
					button.classList.remove('hidden');
				} else {
					button.classList.add('hidden');
				}
			} else if (PER_MODE_TASKS['moodboard'].includes(currentButtonTask)) {
				if (graph?.graphMode === 'moodboard') {
					button.classList.remove('hidden');
				} else {
					button.classList.add('hidden');
				}
			}

			button.onclick = () => {
				if (button.dataset.task === 'common') {
					showCommonIdeasTab(node);
				} else if (button.dataset.task === 'brainstorm') {
					showBrainstormTab(node);
				} else if (button.dataset.task?.startsWith('image')) {
					showImageTab(button.dataset.task as GenerateTask, node);
				} else if (button.dataset.task?.startsWith('video')) {
					showVideoTab(button.dataset.task as GenerateTask, node);
				} else {
					fetchGenerateResults(button.dataset.task as GenerateTask);
				}
			};
		});

	const resultsList = container.querySelector('.results-list')!;
	removeChildren(resultsList);

	const loadingIndicator = container.querySelector('.loading')!;

	if (generateTask !== 'common') {
		container.querySelector('.common-ideas-input')!.classList.add('hidden');
		loadingIndicator.classList.remove('hidden');
		stopAddingNodesToCommonIdeas();
	}

	if (generateTask !== 'brainstorm') {
		container.querySelector('.brainstorm-input')!.classList.add('hidden');
		loadingIndicator.classList.remove('hidden');
		stopAddingNodesToCommonIdeas();
	}

	if (generateTask !== 'image-inspire') {
		container.querySelector('.image-inspire-options')!.classList.add('hidden');
		loadingIndicator.classList.remove('hidden');
	}

	if (generateTask === 'image-inspire' || generateTask === 'image-synthesise') {
		if (node.label) {
			const label = node.label.toLowerCase().replace(/[\W]/g, '/').replace(/\/+/g, '/');
			const requestId = uuidv4();
			currentRequestId = requestId;
			getBoardImages(document.body.dataset.boardId!).then((images) => {
				const earlierResults = images.filter((image) => {
					if (Math.abs(countSlashes(image.description) - countSlashes(label)) < 5) {
						// Number of words is similar
						return (
							image.description.includes(label) ||
							label.includes(image.description) ||
							levenshteinDistance(image.description, label) < label.length / 2
						);
					} else {
						// Label is much shorter than description
						return false;
					}
				});
				if (earlierResults.length > 0) {
					if (currentRequestId === requestId) {
						showGenerateResults(
							generateTask,
							earlierResults.map((image) => ({
								title: image.description,
								snippet: image.url,
							})),
							true,
							[node]
						);
					}
				}
			});
		}
	}

	if (generateTask !== 'video-inspire') {
		container.querySelector('.video-inspire-options')!.classList.add('hidden');
		loadingIndicator.classList.remove('hidden');
	}

	if (generateTask === 'video-inspire') {
		if (node.label) {
			const label = node.label.toLowerCase().replace(/[\W]/g, '/').replace(/\/+/g, '/');
			const requestId = uuidv4();
			currentRequestId = requestId;
			ncApi.getBoardVideos(document.body.dataset.boardId!).then((videos) => {
				const earlierResults = videos.filter((video) => {
					if (Math.abs(countSlashes(video.description) - countSlashes(label)) < 5) {
						// Number of words is similar
						return (
							video.description.includes(label) ||
							label.includes(video.description) ||
							levenshteinDistance(video.description, label) < label.length / 2
						);
					} else {
						// Label is much shorter than description
						return false;
					}
				});
				if (earlierResults.length > 0) {
					if (currentRequestId === requestId) {
						showGenerateResults(
							generateTask,
							earlierResults.map((video) => ({
								title: video.description,
								snippet: video.url,
							})),
							true,
							[node]
						);
					}
				}
			});
		}
	}

	container.querySelector('.ideate-options')!.classList.add('hidden');
	container.querySelector('.video-inspire-options video-generator-with-quota')?.remove();
	container.querySelector('.image-inspire-options image-generator-with-quota')?.remove();
};

export const createSingleNodeFromGenerate = (
	node: NCNode,
	label: string,
	src?: string,
	isVideo: boolean = false
): NCNode | void => {
	const graph = globalThis.neuroCreate.graph;

	if (graph) {
		return (graph as InspireGraph).addSingleNode(node, label, undefined, [], [], src, isVideo);
	}
};

export const isGeneratePanelVisible = () => {
	return !document.querySelector('#generate-container')?.classList.contains('hidden');
};
