import { deactivateTutorialButton, exitTutorial, startTutorial } from './tutorial';
import { clearNonBlockingMessage, showNonBlockingMessage } from '../ui/uiUtils';
import { startBrandingTutorial } from './brandingTutorial';
import Graph from '../graphs/Graph';
import { GraphMode } from '../../../../src/commonTypes';
import { IntroStep } from 'intro.js/src/core/steps';
import { capitalizeFirstLetter } from '../../../../src/commonMisc';
import { disableChatbot, enableChatbot } from './tutorialEvents';

const videoIds = {
	brainstorming: {
		inspire: 'u8EIIRpQf9k',
		ideate: 'PEzNVJxy_4I',
		moodboard: '_iw81Gwt80Q',
	},
	marketing: {
		inspire: 'dFB5_KzSC90',
		ideate: 'nF2OBo29ji8',
		moodboard: 'oQtdUIsRZks',
	},
};
const getVideoId = (templateName: string, graphMode: GraphMode): string | undefined => {
	return videoIds[templateName as keyof typeof videoIds]
		? videoIds[templateName as keyof typeof videoIds][graphMode]
		: undefined;
};

export const start = (templateId: string, graph: Graph, force = false) => {
	exitTutorial();

	const tutorialButton = document.querySelector<HTMLButtonElement>('#tutorial')!;
	tutorialButton.innerText = `${capitalizeFirstLetter(templateId)} tutorial`;

	if (templateId === 'branding') {
		setTimeout(() => {
			disableChatbot();
			console.log("Disabling chatbot");
			startBrandingTutorial(graph, force);
		}, 200);
		return;
	}

	const totalHiddenNodes = graph.nodes.filter((n) => n.hidden).length;

	if (
		templateId &&
		templateId !== 'board' &&
		graph.graphMode === 'inspire' &&
		totalHiddenNodes > 0
	) {
		graph.zoomToScale(0.4);
	}

	const totalNodes = graph.nodes.length;
	if (totalHiddenNodes) {
		let count = totalNodes - totalHiddenNodes;
		standardTemplateTutorialStart(templateId, graph.graphMode);
		// disableChatbot();
		let timeInterval = 2000; // ms
		let paused = false;
		const placeAnotherNode = (reverseOrder = false) => {
			const nodeToHide = graph.nodes.filter((n) => !n.hidden).pop();
			const nodeToShow = graph.nodes.filter((n) => n.hidden).shift();

			if (reverseOrder) {
				if (nodeToHide) {
					count -= 1;
					nodeToHide.hidden = true;
					if (neuroCreate.saveNodes) {
						neuroCreate.saveNodes.update([nodeToHide]);
					}
				}
			} else {
				if (nodeToShow) {
					count += 1;
					delete nodeToShow.hidden;
					if (neuroCreate.saveNodes) {
						neuroCreate.saveNodes.update([nodeToShow]);
					}

					if (!paused) {
						showNonBlockingMessage(
							`Placing nodes from ${templateId} template (${count} of ${totalNodes})`,
							[
								{
									label: timeInterval === 2000 ? 'Fast-forward' : 'Normal speed',
									onClick: () => {
										timeInterval = timeInterval === 2000 ? 400 : 2000;
									},
								},
								{
									label: 'Pause',
									onClick: () => {
										paused = true;
									},
								},
							]
						);
						setTimeout(placeAnotherNode, timeInterval);
					}
				} else {
					clearNonBlockingMessage();
					templateTutorialEnd(templateId, graph.graphMode);
					return;
				}
			}

			if (paused) {
				const nextNodeToHide = graph.nodes.filter((n) => !n.hidden).pop();
				showNonBlockingMessage(
					`Placed ${count} of ${totalNodes} nodes from ${templateId} (paused)`,
					[
						{
							label: 'One Step Back',
							onClick: () => {
								placeAnotherNode(true);
							},
							disabled: nextNodeToHide == undefined,
						},
						{
							label: 'One Step Forward',
							onClick: () => {
								placeAnotherNode();
							},
						},
						{
							label: 'Continue',
							onClick: () => {
								paused = false;
								placeAnotherNode();
							},
						},
					]
				);
			}
		};
		// Show nodes one-by-one if this is a template
		placeAnotherNode();
	} else if (force || !graph.getHintsShown()) {
		standardTemplateTutorialStart(templateId, graph.graphMode);
		graph.setHintsShown();
	}
};

const standardTemplateTutorialStart = (templateId: string, graphMode: GraphMode) => {
	const steps: Array<Partial<IntroStep>> = [];
	const hasHiddenNodes = globalThis.neuroCreate.graph!.nodes.filter((n) => n.hidden).length > 0;
	if (graphMode === 'ideate') {
		const videoId = getVideoId(templateId, 'ideate');
		if (videoId) {
			steps.push(
				{
					title: `${capitalizeFirstLetter(templateId)}: Ideate`,
					intro: `Welcome to Ideate mode for the ${templateId} template. <br><br> Here you can use AI to analyse the concepts added earlier in Inspire`,
				},
				{
					title: `Learn how to use Ideate`,
					tooltipClass: 'video-embed',
					intro: `Please watch this short video to see learn how to use the analyses in Ideate mode. <br>
<p class="embed"><iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/${videoId}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>`,
				},
				{
					title: 'Complete 🎉',
					element: '#board-stage-switcher',
					intro:
						'You have completed the Ideate tutorial! <br><br>When you’re ready, navigate to the Synthesise stage.',
				}
			);
		}
	} else if (graphMode === 'inspire') {
		steps.push({
			title: `Tutorial: ${templateId}`,
			intro: `Welcome to the ${templateId} template. 
<br><br>This tutorial will guide you through the entire process of how this ${templateId} board was created.
<br><br> We start in Inspire mode. Later, you'll progress to see the Ideate and Synthesise modes too.
${
	hasHiddenNodes
		? `<br><br>As you'll see, the board is currently loading nodes from the template`
		: ''
}`,
		});
	} else {
		steps.push({
			title: `${capitalizeFirstLetter(templateId)}: Synthesise`,
			intro: `Welcome to Synthesise mode for the ${templateId} template.
${
	hasHiddenNodes
		? `<br><br>As you'll see, the board is currently loading nodes from the template`
		: ''
}`,
		});
	}
	startTutorial(
		{
			steps,
		},
		{
			onexit: () => {
				if (graphMode === 'ideate') {
					deactivateTutorialButton();
				} else {
					const hasHiddenNodes =
						globalThis.neuroCreate.graph!.nodes.filter((n) => n.hidden).length > 0;
					if (!hasHiddenNodes) {
						templateTutorialEnd(templateId, graphMode);
					}
				}
			},
		}
	);
};

const templateTutorialEnd = (templateId: string, graphMode: GraphMode) => {
	let nextStage = null;
	if (graphMode === 'ideate') {
		nextStage = 'Synthesise';
	} else if (graphMode === 'inspire') {
		nextStage = 'Ideate';
	}
	const videoId = getVideoId(templateId, graphMode);
	const steps: Array<Partial<IntroStep>> = [];
	if (videoId) {
		steps.push({
			title: `Learn how to use ${graphMode === 'inspire' ? 'Inspire' : 'Synthesise'}`,
			tooltipClass: 'video-embed',
			intro: `Now you see the completed ${templateId} board.<br><br>
Please watch this short video to see learn how ${
				graphMode === 'inspire' ? 'these concepts were' : 'this Synthesise moodboard was'
			} created, with help from our AI tools. <br>
<p class="embed"><iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/${videoId}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>`,
		});
	}
	if (nextStage) {
		steps.push({
			title: 'Completed 🎉',
			element: '#board-stage-switcher',
			intro: `Feel free to explore the board!
<br><br>When you are ready, switch to the next stage: <strong>${nextStage}</strong>`,
		});
	} else {
		steps.push({
			title: 'Completed 🎉',
			intro: `You have now completed the entire tutorial for the ${templateId} template!
<br><br> You may continue to explore the ${templateId} template or go back to the dashboard to try creating a new board from scratch`,
		});
	}
	startTutorial(
		{
			steps,
		},
		{
			onexit: () => {
				deactivateTutorialButton();
				enableChatbot();
			},
		}
	);
};
