import Graph from './Graph';
import * as d3 from 'd3';
import avoidLinkForce from './avoidLinkForce';
import linkLengthForce from './linkLengthForce';
import groupForce from './groupForce';
import { NCNode } from '../../../../src/commonTypes';

export const initPhysics = (graph: Graph) => {
	if (graph.isPhysicsActive()) {
		console.log('[initPhysics called]');

		const simulation = d3
			.forceSimulation(graph.nodes)
			.force(
				'collision',
				d3
					.forceCollide()
					.radius(
						(node) => graph.nodeRadius * (graph.nodes[node.index!]?.style === 'rect' ? 2.5 : 1.5)
					)
					.strength(1)
			)
			.force('linkCollision', avoidLinkForce(graph.links))
			.force('linkLength', linkLengthForce(graph.links))
			.force('group', groupForce(graph.nodeGroups))
			.on('tick', graph.onTick.bind(graph))
			.on('end', () => {
				graph.lockAllNodesInPlace();
			});
		// .velocityDecay(0.2);
		return simulation;
	}
	return undefined;
};

export const resetPhysicsMovement = (graph: Graph) => {
	if (graph.simulation) {
		graph.simulation.alpha(1).restart();
	}
};

export const ensurePhysicsAlphaIsHighEnough = (graph: Graph) => {
	if (graph.simulation) {
		if (graph.simulation.alpha() < 0.5) {
			graph.simulation.alpha(0.5);
		}
	}
};

// export const updatePhysics = (graph: Graph) => {
// 	if (graph.isPhysicsActive() && graph.simulation) {
// 		console.log('[updatePhysics]');

// 		graph.simulation.nodes(graph.nodes);
// 		const linkCollision = graph.simulation.force('linkCollision') as ReturnType<
// 			typeof avoidLinkForce
// 		>;
// 		linkCollision?.links(graph.links);
// 		const linkLength = graph.simulation.force('linkLength') as ReturnType<typeof linkLengthForce>;
// 		linkLength?.links(graph.links);
// 		const group = graph.simulation.force('group') as ReturnType<typeof groupForce>;
// 		group?.groups(graph.nodeGroups);
// 		graph.simulation.alpha(1).restart();
// 	}
// };

export const updatePhysics = (graph: Graph) => {
	if (graph.isPhysicsActive() && graph.simulation) {
		graph.simulation.nodes(graph.nodes);
		const linkCollision = graph.simulation.force('linkCollision') as ReturnType<
			typeof avoidLinkForce
		>;
		linkCollision?.links(graph.links);
		const linkLength = graph.simulation.force('linkLength') as ReturnType<typeof linkLengthForce>;
		linkLength?.links(graph.links);
		const group = graph.simulation.force('group') as ReturnType<typeof groupForce>;
		group?.groups(graph.nodeGroups);
		const collisionForce = graph.simulation.force('collision') as d3.ForceCollide<NCNode>;
		const nodeDensityFactor = Math.min(1, 500 / graph.nodes.length);
		const padding = 5;

		if (!collisionForce) {
			graph.simulation.force(
				'collision',
				d3
					.forceCollide()
					.radius(
						(node) =>
							graph.nodeRadius *
								nodeDensityFactor *
								(graph.nodes[node.index!]?.style === 'rect' ? 2.5 : 1.5) +
							padding
					)
					.strength(1)
			);
		} else {
			collisionForce
				.radius(
					(node) =>
						graph.nodeRadius *
							nodeDensityFactor *
							(graph.nodes[node.index!]?.style === 'rect' ? 2.5 : 1.5) +
						padding
				)
				.strength(1)
		}
		graph.simulation.alphaDecay(0.02).alphaTarget(0.1);
		graph.simulation.alpha(1).restart();
	}
};
