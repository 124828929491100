import { AnalysisKey } from '../../../src/commonConstants';

export const HEART_COLOURS: string[] = [
	'orange',
	'fuchsia',
	'red',
	'green', // BaseUserColour
	'dark',
	'violet',
	'yellow',
	'cyan', // AdditionalUserColour
	'blue',
	'pink',
	'lime',
	'teal', // ExtraUserColour
	'maroon',
	'turquoise',
	'purple',
	'navy',
	'salmon',
	'forestGreen',
	'olive',
	'chocolate',
];

export const generateCirclePositions = (numPoints: number, radius: number) => {
	const positions = [];
	const angleIncrement = (2 * Math.PI) / numPoints;

	for (let i = 0; i < numPoints; i++) {
		const angle = i * angleIncrement;
		const x = Math.cos(angle) * radius - 8;
		const y = Math.sin(angle) * radius - 8;
		positions.push({ x, y });
	}

	return positions;
};

// Generate 20 positions within a circle of radius 50
export const HEART_POSITIONS = generateCirclePositions(20, 52);

export const IDEATE_RECOMMENDATIONS = [
	{
		name: 'Brand Values',
		recommendations: [
			'Psych.cluster',
			'Archetype.cluster',
			'Greek.cluster',
			'Themes.cluster',
		] as Array<AnalysisKey>,
	},
	{
		name: 'Storytelling: Character and Plot Design',
		recommendations: [
			'Psych.cluster',
			'Archetype.cluster',
			'Greek.cluster',
			'Plots.cluster',
			'Themes.cluster',
		] as Array<AnalysisKey>,
	},
	{
		name: 'Target Audience',
		recommendations: [
			'Psych.cluster',
			'Archetype.cluster',
			'Greek.cluster',
			'Themes.cluster',
		] as Array<AnalysisKey>,
	},
	{
		name: 'Tone of Voice & Communication',
		recommendations: [
			'Psych.cluster',
			'Archetype.cluster',
			'Greek.cluster',
			'Plots.cluster',
			'Themes.cluster',
		] as Array<AnalysisKey>,
	},
	{
		name: 'Stakeholder, User, Player Motivation',
		recommendations: ['Motivations.cluster', 'Themes.cluster'] as Array<AnalysisKey>,
	},
	{
		name: 'Innovation Strategy',
		recommendations: [
			'colors.cluster',
			'Psych.cluster',
			'Archetype.cluster',
			'Greek.cluster',
			'Themes.cluster',
		] as Array<AnalysisKey>,
	},
];

export const WINDOW_BREAKPOINTS = {
	mobile: 800,
	tablet: 950,
	laptop: 1200,
	desktop: 1400,
};
