import { CLIENT_CONFIG, PUBLIC_DOMAINS } from './commonConstants';
import { BoardUser, LikedItem, Motivations, UserPersona } from './commonTypes';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const stopwords = require('@stdlib/datasets-stopwords-en') as () => Array<string>;

// eslint-disable-next-line @typescript-eslint/no-var-requires
const expandContractions = require('@stdlib/nlp-expand-contractions') as (text: string) => string;

export const stripPunctuation = (rawString: string) => {
	return (
		rawString
			// Remove any instance of 's
			.replace(/((\w)[\\'’]s( |$))/g, '$2 ')
			// Remove everything except alphanumeric characters and whitespace:
			// apostrophes can be left in as they may be parts of names
			.replace(/[^\w\s\\'’-]|_/g, '')
			// remove dashes that are not surrounded by a word on both sides
			.replace(/([^\w])-([^\w])/g, '$1$2')
			// Remove multiple whitespace characters to one space
			.replace(/\s+/g, ' ')
			.trim()
	);
};

export const splitWords = (words: string, shouldRemoveStopwords = true) => {
	if (shouldRemoveStopwords) {
		return removeStopwords(stripPunctuation(expandContractions(words)).split(' '));
	} else {
		return stripPunctuation(expandContractions(words)).split(' ');
	}
};

export const formatCurrency = (value: number, currency = 'GBP') => {
	const currencyFormat = new Intl.NumberFormat(undefined, {
		style: 'currency',
		currency,
		minimumFractionDigits: value % 1 === 0 ? 0 : 2,
	});
	return currencyFormat.format(value);
};

export const formatDate = (date: Date | string): string => {
	const dateToFormat = typeof date === 'string' ? new Date(date) : date;

	return dateToFormat.toLocaleDateString(undefined, {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	});
};

export const formatPercent = (value: number) => {
	const percentFormat = new Intl.NumberFormat(undefined, {
		style: 'percent',
		maximumFractionDigits: 0,
	});
	return percentFormat.format(value);
};

export const filterLikesToType = (likedItems: Array<LikedItem>, likeType: LikedItem['type']) => {
	return likedItems.filter((list) => list['type'] === likeType);
};

const additionalStopwords = ['am'];
const englishStopwords = stopwords();
englishStopwords.push(...additionalStopwords);

export const removeStopwords = (tokens: Array<string>) => {
	return tokens.filter(function (value) {
		return englishStopwords.indexOf(value.toLowerCase()) === -1;
	});
};

// From https://www.tutorialspoint.com/levenshtein-distance-in-javascript
export const levenshteinDistance = (str1 = '', str2 = ''): number => {
	const track: Array<Array<number>> = Array(str2.length + 1)
		.fill(null)
		.map(() => Array(str1.length + 1).fill(null) as number[]);
	for (let i = 0; i <= str1.length; i += 1) {
		track[0][i] = i;
	}
	for (let j = 0; j <= str2.length; j += 1) {
		track[j][0] = j;
	}
	for (let j = 1; j <= str2.length; j += 1) {
		for (let i = 1; i <= str1.length; i += 1) {
			const indicator = str1[i - 1] === str2[j - 1] ? 0 : 1;
			track[j][i] = Math.min(
				track[j][i - 1] + 1, // deletion
				track[j - 1][i] + 1, // insertion
				track[j - 1][i - 1] + indicator // substitution
			);
		}
	}
	return track[str2.length][str1.length];
};

export const userPersonaToString = (persona: UserPersona): string => {
	return `Name: ${persona.name.trim()}
  
Biography: ${persona.biography.trim()}

Goals: ${persona.goals.trim()}

Needs & wants: ${persona.needs.trim()}

Pain points: ${persona.painPoints.trim()}

Motivations: ${persona.motivations.trim()}

Influences: ${persona.influences.trim()}

Technical Knowledge: ${persona.scores.tech}/10; Ambition: ${
		persona.scores.ambition
	}/10; Happiness: ${persona.scores.happiness}/10
`;
};

export const motivationsToString = (motivations: Motivations): string => {
	return motivations.stakeholders.map((s) => `${s.name}: ${s.description}`).join('\n\n');
};

export const isTestEnvironment = () => {
	return process.env.ENVIRONMENT === 'staging' || process.env.BASE_URL?.includes('localhost');
};

export function capitalizeFirstLetter(text: string) {
	return text.charAt(0).toUpperCase() + text.slice(1);
}

export const removeDuplicates = <T>(arr: Array<T>): Array<T> => {
	return arr.filter((value, index) => arr.indexOf(value) === index);
};

const isClientUserPresent = (users: BoardUser[], emailEndings: string[]) => {
	return users
		.map((user) => user.email.trim().toLowerCase())
		.some((email) => emailEndings.some((ending) => email.endsWith(ending)));
};

export const getTopicAssistantID = (users: BoardUser[]): string => {
	for (const client of Object.values(CLIENT_CONFIG)) {
		if (isClientUserPresent(users, client.emailEndings)) {
			return process.env[client.topicVariable] || '';
		}
	}

	return process.env.OPENAI_ASSISTANT_ID || '';
};

export const getEmpathiseAssistantID = (users: BoardUser[]): string => {
	for (const client of Object.values(CLIENT_CONFIG)) {
		if (isClientUserPresent(users, client.emailEndings)) {
			return process.env[client.empathiseVariable] || '';
		}
	}

	return process.env.EMPATHISE_ASSISTANT_ID || '';
};

export const detectBrowser = () => {
	const userAgent = navigator.userAgent;

	if (/edge/i.test(userAgent)) {
		return 'Edge';
	}
	if (/chrome/i.test(userAgent) && !/edge/i.test(userAgent)) {
		return 'Chrome';
	}
	if (/firefox/i.test(userAgent)) {
		return 'Firefox';
	}
	if (/safari/i.test(userAgent) && !/chrome/i.test(userAgent)) {
		return 'Safari';
	}
	if (/msie|trident/i.test(userAgent)) {
		return 'Internet Explorer';
	}
	if (/opera/i.test(userAgent)) {
		return 'Opera';
	}
	return 'Unknown';
};

export function isPublicDomain(email: string) {
	const domain = email.split('@')[1];
	return PUBLIC_DOMAINS.includes(domain);
}

export function extractCompanyName(email: string) {
	const domain = email.split('@')[1];
	const parts = domain.split('.');
	return parts.length > 2 ? parts[1].toUpperCase() : parts[0].toUpperCase(); // Get company name
}
